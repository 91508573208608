import {Component,Inject,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { CosteEmpresaService } from 'src/app/Services/coste-empresa.service';
import { takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';
import { FamiliaCosteService } from 'src/app/Services/familia-coste.service';
import { ProyectosService } from 'src/app/Services/proyectos.service';

@Component({
  selector: 'app-pop-up-coste',
  templateUrl: './pop-up-coste.component.html',
  styleUrls: ['./pop-up-coste.component.css']
})
export class PopUpCosteComponent extends UnsubscribesDestroy implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PopUpCosteComponent>,
    private proyectosSE: ProyectosService,
    public loaderSE: LoaderService,
    protected costeSE: CosteEmpresaService,
    protected familiaCosteSE: FamiliaCosteService,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
  }
  descripcion = '';
  meses = [
    {id: 1, nombre: 'Enero'},
    {id: 2, nombre: 'Febrero'},
    {id: 3, nombre: 'Marzo'},
    {id: 4, nombre: 'Abril'},
    {id: 5, nombre: 'Mayo'},
    {id: 6, nombre: 'Junio'},
    {id: 7, nombre: 'Julio'},
    {id: 8, nombre: 'Agosto'},
    {id: 9, nombre: 'Septiembre'},
    {id: 10, nombre: 'Octubre'},
    {id: 11, nombre: 'Noviembre'},
    {id: 12, nombre: 'Diciembre'},
  ];
  year = null;
month = null;
  tipo = 'Variable';
  proyectos = [];
  proyecto = null;
  proyectoOpcional = false;
  fechaOpcional = false;
  ngOnInit(): void {
    this.year = this.modalData.year;
    this.month = this.modalData.month;
    if(this.month == null){
      this.fechaOpcional = true;
    }
    this.proyecto = this.modalData.proyecto;
    if(this.proyecto == null){
      this.proyectoOpcional = true;
    }
    if(this.modalData.tipo == 0){
      this.tipo = 'Fijo';
    }
    if(this.modalData.tipo == 1){
      this.tipo = 'Variable';
    }
    if(this.modalData.tipo == 2){
      this.tipo = 'Periodo';
    }
    this.loaderSE.open();
    this.proyectosSE.getAllProyectos(9999,1);
    this.proyectosSE.getResult().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe((value) => {
      if (value == null) {
        return;
      }
      this.loaderSE.closeContador();
      this.proyectos = value;
      this._unsubscribeIndividual2.next('');
    });
    this.loaderSE.open();
    this.familiaCosteSE.getFamilias();
    this.familiaCosteSE.getResult().
    pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this.familias = value;
      this.loaderSE.closeContador();
      this._unsubscribeIndividual.next('');
    }
    );
  }
  familias = [];
  confirmarGuardarBorrador(valor: boolean) {
    this.closePopup(valor);
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  opcionSeleccionadaTipo(valor) {
    this.tipo = valor;
  }
  iva = '21%';
  opcionSeleccionadaIva(valor) {
    this.iva = valor;
  }
  coste = 0;
  concepto = "";
  familia = null;
  guardarInfo(){
    let ivaNumber = 0;
    //buscar % en iva
    let ivaString = this.iva;
    if(this.iva.indexOf('%') != -1){
      ivaNumber = Number(this.iva.substring(0,this.iva.length-1));
    }

    let res = {
      concepto:this.concepto,
      cantidad:this.coste,
      tipo:0,
      iva:ivaNumber,
      familia:this.familia,
      proyecto:this.proyecto
    }
    if(this.tipo == 'Variable'){
      res.tipo = 1;
    }
    if(this.tipo == 'Periodo'){
      res.tipo = 2;
    }
    this.loaderSE.open();
    this.costeSE.guardarCoste(this.year,this.month,res);
    this.costeSE.getResultIndividual()
    .pipe(takeUntil(this._unsubscribeIndividual2))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      console.log(value);
      this.loaderSE.closeContador();
      this._unsubscribeIndividual2.next('');
      this.closePopup(true);
    });
  

  }
}