import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { DepartamentosService } from 'src/app/Services/departamentos.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-Nuevo-Integrante',
  templateUrl: './Nuevo-Integrante.component.html',
  styleUrls: ['./Nuevo-Integrante.component.css'],
})
export class NuevoIntegranteComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  nombre: string = '';
  apellidos: string = '';
  horario: number;
  departamento: string = 'Selecciona un departamento';
  username: string = '';

  vacioNombre: boolean = false;
  vacioTipoCoste: boolean = false;
  vacioApellidos: boolean = false;
  vacioHorario: boolean = false;
  vacioDepartamento: boolean = false;
  vacioUsername: boolean = false;
  vacioCoste: boolean = false;
  vacioCosteAnual: boolean = false;
  departamentos = [];

  @Output() estado = new EventEmitter<boolean>();

  constructor(
    public popup: PopupService,
    public router: Router,
    private loaderSE: LoaderService,
    protected userService: UserService,
    private notificacionesSE: NotificacionesService,
    protected departamentosSE: DepartamentosService
  ) {
    super();
  }

  ngOnInit(): void {
    // if(localStorage.getItem("login") === "needLogin"){
    //   this.router.navigate(["/login"]);
    // }
    this.loaderSE.open();
    this.departamentosSE.getDepartamentosByEmpresa();

    this.departamentosSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.departamentos = value;
        this.loaderSE.closeContador();
        this._unsubscribeIndividual.next('');
      });
  }

  cerrarAddIntegrante() {
    this.router.navigate([localStorage.getItem('lastRoute')]);
  }
  tipoCoste = null;
coste = null;
costeAnual = null;
  enviarDatos() {
    let datos = {
      nombre: this.nombre,
      apellidos: this.apellidos,
      horas: this.horario,
      departamentos: this.departamento,
      username: this.username,
      coste: this.coste,
      costeAnual: this.costeAnual,
      tipoCoste: this.tipoCoste,
    };
    this.userService.createUser(datos);
    this.loaderSE.open();
    this.userService
      .getResultUpdate()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        this.notificacionesSE.showFeedBack(value.message);
        this._unsubscribeIndividual.next('');
      });
  }

  comprobarDatos() {
    var valido = true;
    if (this.nombre == '') {
      this.vacioNombre = true;
      valido = false;
    } else {
      this.vacioNombre = false;
    }

    if (this.apellidos == '') {
      this.vacioApellidos = true;
      valido = false;
    } else {
      this.vacioApellidos = false;
    }

    if (this.horario > 0) {
      this.vacioHorario = false;
    } else {
      this.vacioHorario = true;
      valido = false;
    }
    
    if (this.tipoCoste == null) {
      this.vacioTipoCoste = true;
      valido = false;
    } else {
      this.vacioTipoCoste = false;
    }

    if (
      this.departamento == '' ||
      this.departamento == 'Selecciona un departamento'
    ) {
      this.vacioDepartamento = true;
      valido = false;
    } else {
      this.vacioDepartamento = false;
    }

    if (this.username == '' || this.username == 'Selecciona un username') {
      this.vacioUsername = true;
      valido = false;
    } else {
      this.vacioUsername = false;
    }

    if (this.coste == null) {
      this.vacioCoste = true;
      valido = false;
    } else {
      this.vacioCoste = false;
    }

    if (this.costeAnual == null) {
      this.vacioCosteAnual = true;
      valido = false;
    } else {
      this.vacioCosteAnual = false;
    }

    if (valido == true) {
      this.enviarDatos();
    }
  }

  vaciarCampos() {
    this.nombre = '';
    this.apellidos = '';
    this.horario = undefined;
    this.departamento = 'Selecciona un departamento';
    this.username = 'Selecciona un username';
  }
}
