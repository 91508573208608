<div class="generalContainer">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]="'equipo'"></sidebar>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataHeaderContainer">
      <app-header-bar
        titulo="Añadir integrante"
        [cerrar]="true"
      ></app-header-bar>
    </div>
    <div class="dataFormContainer">
      <div class="formFirstBlock">
        <div class="formFirstBlock-one">
          <label for="nombre" [class.vacio]="vacioNombre == true">Nombre</label>
          <input
            type="text"
            placeholder="Introduce el nombre"
            [(ngModel)]="nombre"
            [class.vacio]="vacioNombre == true"
          />
        </div>
        <div class="formFirstBlock-two">
          <label for="apellidos" [class.vacio]="vacioApellidos == true">Apellidos</label>
          <input
            type="text"
            placeholder="Introduce los apellidos"
            [(ngModel)]="apellidos"
            [class.vacio]="vacioApellidos == true"
          />
        </div>
      </div>
      <div class="formSecondBlock">
        <div class="formSecondBlock-one">
          <label for="departamento" [class.vacio]="vacioDepartamento == true"
            >Perfiles</label
          >
          
          <ng-select
          [items]="departamentos"
          placeholder="Selecciona la familia"
          bindLabel="nombre"
          bindValue="id"
          [multiple]="true"
          [(ngModel)]="departamento"
        >
        </ng-select>
        </div>
        <div class="formSecondBlock-two">
          <label for="Horario" [class.vacio]="vacioHorario == true"
            >Horas semanales</label
          >
          <input
            type="number"
            placeholder="Introduce el horario"
            [(ngModel)]="horario"
            [class.vacio]="vacioHorario == true"
          />
        </div>
      </div>
      <div class="formThirdBlock">
        <div class="formThirdBlock-one">
          <label for="costeAnual" [class.vacio]="vacioCosteAnual == true"
            >Coste anual</label
          >
          <input
            type="text"
            placeholder="Introduce el coste anual"
            [(ngModel)]="costeAnual"
            [class.vacio]="vacioCosteAnual == true"
          />
        </div>        
        <div class="formThirdBlock-two">
          <label for="coste" [class.vacio]="vacioCoste == true"
            >Coste por hora</label
          >
          <input
            type="text"
            placeholder="Introduce el coste/hora"
            [(ngModel)]="coste"
            [class.vacio]="vacioCoste == true"
          /></div>
        </div>
      <div class="formThirdBlock">
        <div class="formSecondBlock-one">
          <label for="tipocoste" [class.vacio]="vacioTipoCoste == true"
            >Tipo de coste</label
          >
          
          <ng-select
          [items]="['Directo', 'Indirecto']"
          placeholder="Selecciona el tipo de coste"
          [(ngModel)]="tipoCoste"
        ></ng-select></div>
          <div class="formThirdBlock-two">
            <label for="empresa" [class.vacio]="vacioUsername == true"
              >Mail</label
            >
            <input
              type="text"
              placeholder="Introduce el mail"
              [(ngModel)]="username"
              [class.vacio]="vacioUsername == true"
            />
          </div>
      </div>
    </div>
    <div class="botonesContainer">
      <button (click)="comprobarDatos()" type="button">Añadir al equipo</button>
    </div>
  </div>
</div>
