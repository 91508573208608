import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions } from 'chart.js';
import { ChartComponent } from 'ng-apexcharts';
import { takeUntil } from 'rxjs';
import { PopUpCosteComponent } from 'src/app/Components/pop-up-coste/pop-up-coste.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { CosteEmpresaService } from 'src/app/Services/coste-empresa.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { PopupService } from 'src/app/Services/popup.service';

@Component({
  selector: 'app-costes',
  templateUrl: './costes.component.html',
  styleUrls: ['./costes.component.css']
})
export class CostesComponent extends UnsubscribesDestroy implements OnInit {

  public chartOptions!: Partial<ChartOptions> | any;

    
  constructor(
    protected loaderSE: LoaderService,
    public router: Router,
    protected popup: PopupService,
    protected costeSE: CosteEmpresaService) {
      super();
      this.chartOptions = {
        colors: ["#fc464e","#ffa5c8","#dca8ab"],
        series: [
          {
            name: 'Costes Fijos',
            data: [],
          },
          {
            name: 'Costes Variables',
            data: [],
          },
          {
            name: 'Costes Periodo',
            data: [],
          }
        ],
        chart: {
          type: 'bar',
          height: 295,
          //stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            
            borderRadius: 2,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: 'last'
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 10,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [
            'ENE',
            'FEB',
            'MAR',
            'ABR',
            'MAY',
            'JUN',
            'JUL',
            'AGO',
            'SEP',
            'OCT',
            'NOV',
            'DIC'
          ],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels:{
            show: true,
            style:{
              colors:"#515655",
              fontFamily: 'Satoshi',
              fontWeight: 500,
            }
          }
        },
        yaxis: {
          
          labels:{
            show: true,
            style:{
              colors:"#a1a3a2",
              fontFamily: 'Satoshi',
              fontWeight: 500,
            },
            formatter: (value) => { 
              //formatear en formato moneda
              let formatter = new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR',
              });
              return formatter.format(value);
            },
          }
        },
        legend:{
          position:'top',
          horizontalAlign: 'right',
          fontFamily: 'Satoshi',
          markers: {
            width: 30,
            height:8,
            radius: 8,
            offsetX: -15,
          },          
          itemMargin: {
            horizontal: 30,
            vertical: 0
          },
        }
      };
     }

     @ViewChild('chart')
     chart!: ChartComponent;
  ngOnInit(): void {
    this.guardarAnyos();
    this.cambiarAnyo();
    localStorage.setItem('lastRoute', this.router.url);
  }
  fijo = [];
  variable = [];
  periodo = [];
  total = [];
  categorizado = true;

  opcionSeleccionada(event: string) {
    console.log(event);
    if(event == "Categorizados"){
      this.categorizado = true;
    }else{
      this.categorizado = false;
    }
    console.log(this.categorizado);
    this.pintarGraficoBarras();
  }
  cambiarAnyo(){
    this.loaderSE.open();
    this.actualizando = true;
    this.costeSE.getCostesYear(this.anyoSeleccionado);
    this.costeSE.getResult()
    .pipe(takeUntil(this._unsubscribeIndividual2))
    .subscribe((value) => {
      if (value == null) {
        return;
      }

      this.meses = value;
      this.total = [];
      this.fijo = [];
      this.variable = [];
      this.periodo = [];
      for (let i = 0; i < this.meses.length; i++) {
        if(this.meses[i].Fijo == 0){
          this.fijo.push(0.1);
        }else{
          this.fijo.push(this.meses[i].Fijo);
        }
        if(this.meses[i].Variable == 0){
          this.variable.push(0.1);
        }else{
          this.variable.push(this.meses[i].Variable);
        }
        if(this.meses[i].Periodo == 0){
          this.periodo.push(0.1);
        }else{
          this.periodo.push(this.meses[i].Periodo);
        }
        this.total.push(this.meses[i].Fijo + this.meses[i].Variable + this.meses[i].Periodo);
      }
      this.pintarGraficoBarras();
      this.actualizando = false;
      this.loaderSE.closeContador();
      this._unsubscribeIndividual2.next('');
    });
  }
  pintarGraficoBarras(){
    if(this.categorizado){
      this.chartOptions.series = [
        {
          name: 'Costes Fijos',
          data: [],
        },
        {
          name: 'Costes Variables',
          data: [],
        },
        {
          name: 'Costes Periodo',
          data: [],
        }
      ];
      this.chartOptions.series[0].data = this.fijo;
      this.chartOptions.series[1].data = this.variable;
      this.chartOptions.series[2].data = this.periodo;
    }else{
      this.chartOptions.series = [
        {
          name: 'Total',
          data: [],
        }
      ];
      this.chartOptions.series[0].data = this.total;        
    }
    if(this.chart != null){
      this.chart.updateSeries(this.chartOptions.series);
    }
  }
  actualizando = false;
  meses = [
    { nombre: 'Enero', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Febrero', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Marzo', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Abril', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Mayo', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Junio', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Julio', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Agosto', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Septiembre', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Octubre', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Noviembre', Periodo: 0, Fijo: 0, Variable:0},
    { nombre: 'Diciembre', Periodo: 0, Fijo: 0, Variable:0}
  ]
  anyos = [];
  anyoSeleccionado = 0;
  mesSeleccionado = null;
  guardarAnyos(){    
    //guardar en anyos el año anterior, el actual y el que viene
    let anyoActual = new Date().getFullYear();
    this.anyoSeleccionado = anyoActual;
    this.anyos.push(anyoActual - 1);
    this.anyos.push(anyoActual);
    this.anyos.push(anyoActual + 1);
  }

  mesActual = new Date().getMonth();
  anyoActual = new Date().getFullYear();

  seleccionarMes(indice){
    if(indice != this.mesSeleccionado){
      this.mesSeleccionado = indice;
    }else{
      this.mesSeleccionado = null;
    }
  }


  abrirPopUp(mes,event){
    event.stopPropagation();
    let data = {year:this.anyoSeleccionado,month:mes};
    this.popup.openPopup(PopUpCosteComponent,data);    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue) {
        this.popup.clearData();
        this.cambiarAnyo();        
        this._unsubscribeIndividual.next('');
      }
    });

    
  }
}
