<div class="contenedor">
  <div class="izquierda">
    <form (submit)="validarCampos()">
      <div class="formulario">
        <div class="logo">
          <img src="../../../assets/Images/Fenech_V2-20.png" />
        </div>
        <div>
          <p class="titulo">Bienvenido</p>
          <p class="subtitulo">Introduce tus datos para ingresar en Fennech</p>
        </div>
        <div class="inputs">
          <input
            type="email"
            name="email"
            [placeholder]="placeHolderEmail"
            [(ngModel)]="email"
            [class.invalido]="vacioEmail === true || emailValido === false"
          />
          <input
            type="password"
            name="password"
            [placeholder]="placeHolderPassword"
            [(ngModel)]="password"
            [class.invalido]="vacioPassword === true"
          />
        </div>
        <div>
          <button (click)="validarCampos()">Iniciar sesión</button>
          <a href="">He olvidado mi contraseña</a>
        </div>
      </div>
    </form>
    <div class="footer">
      <img src="../../../assets/Images/logoQuasarDashboard.png" />
      <p>2023 Copyright © Atlas Venture Builder S.L. Valencia, España</p>
    </div>
    <div class="version">V. {{version}}</div>
  </div>
  <div class="derecha">

  </div>
</div>
