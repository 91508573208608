import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { Clientes } from '../Interfaces/clientes';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class DailyService extends BaseService {
  
  constructor(protected http: HttpClient) {
    super();
  }

    
  obtenerTimeEntryActual(){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Api-Key': localStorage.getItem('id_clockify')
    });
    let options = { headers: headers};
    let url = "https://api.clockify.me/api/v1/workspaces/"+localStorage.getItem('id_clockify_empresa')+"/user/"+localStorage.getItem('user_clockify')+"/time-entries?in-progress=true";
    
    this.http
      .get(url, options)
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error);
          this.checkStatusError(error);
        },
      });
  }

  getDaily(id) {
    this.http
      .get(this.urlApi + 'api/daily/'+id, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.getDaily, args: [id] });
          this.checkStatusError(error);
        },
      });
  }


  editarDaily(id,data){    
    this.http
      .put(this.urlApi + 'api/daily/'+id,data, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("resultUpdate",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.editarDaily, args: [id,data] });
          this.checkStatusError(error);
        },
      });
  }

  createDaily(data) {    
    this.http
      .post(this.urlApi + 'api/daily',data, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("resultUpdate",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultUpdateError', error, { method: this.createDaily, args: [data] });
          this.checkStatusError(error);
        },
      });
  }
  
  deleteDaily(id) {
    var tables_ids:any = []
    let send={ids:tables_ids};
    if(!(id instanceof Array)){send.ids.push(id);}else{send.ids=id;}
    
    this.http
      .delete(this.urlApi + 'api/daily', this.sendBodyOptions(send))
      .subscribe({
        next: (data) => {
          this._resultDelete.next(data);
          this.sendNextResult("resultDelete",data);
        },
        error: (error) => {
          this.sendNextResult('resultDeleteError', error, { method: this.deleteDaily, args: [id] });
          this.checkStatusError(error);
        },
      });
  }

  clockifyDailyId(id,clockify) {    
    this.http
      .post(this.urlApi + 'api/daily/'+id+'/clockify',{clockify:clockify}, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("resultUpdate",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.clockifyDailyId, args: [id,clockify] });
          this.checkStatusError(error);
        },
      });
  }

  validateDailyId(id,validacion,horas=null,avance=0) {    
    this.http
      .post(this.urlApi + 'api/daily/'+id+'/validation',{validacion:validacion, horas:horas, avanceReal:avance}, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("resultUpdate",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.validateDailyId, args: [id,validacion,horas,avance] });
          this.checkStatusError(error);
        },
      });
  }

  getMyDailyByParameters(num_devoluciones, num_pagina, fecha=null) {    
    let query:string = "num_devoluciones="+num_devoluciones+"&num_pagina="+num_pagina;
    if(fecha != null){
      query += "&fecha="+fecha;
    }
    this.http
      .get(this.urlApi + 'api/daily/me?'+query, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("result",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getMyDailyByParameters, args: [num_devoluciones,num_pagina,fecha] });
          this.checkStatusError(error);
        },
      });
  }

  getDailyByParameters(num_devoluciones, num_pagina, usuario=null, fecha=null) {  
    let query:string = "num_devoluciones="+num_devoluciones+"&num_pagina="+num_pagina;
    if(usuario != null){
      query += "&usuario="+usuario;
    }
    if(fecha != null){
      query += "&fecha="+fecha;
    }
    this.http
      .get(this.urlApi + 'api/daily?'+query, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("result",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getDailyByParameters, args: [num_devoluciones,num_pagina,usuario,fecha] });
          this.checkStatusError(error);
        },
      });
  }

  getDatesNotValidated(){
    this.http
      .get(this.urlApi + 'api/daily/notValidated', this.getHeader())
      .subscribe({
        next: (data) => {
            this.sendNextResult("resultIndividual",data);
        },
        error: (error) => {
          this.sendNextResult('resultIndividualError', error, { method: this.getDatesNotValidated, args: [] });
          this.checkStatusError(error);
        },
      });
  }
  getCategoriasDaily() {    
    this.http
      .get(this.urlApi + 'api/motivo/daily', this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("result",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.getCategoriasDaily, args: [] });
          this.checkStatusError(error);
        },
      });
  }

  eliminarCategoriaDaily(ids) {
    var tables_ids:any = []
    let send={ids:tables_ids};
    if(!(ids instanceof Array)){send.ids.push(ids);}else{send.ids=ids;}
    this.http
      .delete(this.urlApi + 'api/motivo/daily', this.sendBodyOptions(send))
      .subscribe({
        next: (data) => {
          this._resultDelete.next(data);
          this.sendNextResult("resultDelete",data);
        },
        error: (error) => {
          this.sendNextResult('resultDeleteError', error, { method: this.eliminarCategoriaDaily, args: [ids] });
          this.checkStatusError(error);
        },
      });
  }

  crearCategoriaDaily(nombre,color) {
    this.http
      .post(this.urlApi + 'api/motivo/daily',{nombre:nombre,color:color}, this.getHeader())
      .subscribe({
        next: (data) => {
          let clientes = [];
          clientes.push(data);
          clientes.forEach((element) => {
            let cliente: Clientes = new Clientes();
            cliente = element;
            this.sendNextResult("result",cliente);
          });
        },
        error: (error) => {
          this.sendNextResult('resultError', error, { method: this.crearCategoriaDaily, args: [nombre] });
          this.checkStatusError(error);
        },
      });
  }
}
