import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { CalculadoraTiempoEstimadoPopupComponent } from 'src/app/Components/CalculadoraTiempoEstimadoPopup/CalculadoraTiempoEstimadoPopup.component';
import { PopupConfirmacionBorradorComponent } from 'src/app/Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { PopupHitoPagoCotizadorComponent } from 'src/app/Components/popup-hito-pago-cotizador/popup-hito-pago-cotizador.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { DepartamentosService } from 'src/app/Services/departamentos.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { NotificacionesService } from 'src/app/Services/notificaciones.service';
import { PopupService } from 'src/app/Services/popup.service';
import { PresupuestoService } from 'src/app/Services/presupuesto.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-nuevo-presupuesto',
  templateUrl: './nuevo-presupuesto.component.html',
  styleUrls: ['./nuevo-presupuesto.component.scss']
})
export class NuevoPresupuestoComponent extends UnsubscribesDestroy implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calcularAltura();
    this.calcularAnchosMeses();
 }

importeTotalHitos(){
  let importe = 0;
  this.presupuesto.hitos_pago.forEach(element => {
    importe += element.importe;
  });
  return importe;
}
eliminarHito(hito){
  //mostrar popup de confirmacion  
  this.popupSE.openPopup(PopupConfirmacionBorradorComponent, {
    descripcion: "¿Estas seguro que quieres eliminar el hito?",
  });
  this.popupSE.returnData().pipe(takeUntil(this._unsubscribeIndividual)).subscribe(res=>{
    if(res==null){return}
    if(res.returnValue){
      this.presupuesto.hitos_pago.splice(this.presupuesto.hitos_pago.indexOf(hito),1);
    }
    this._unsubscribeIndividual.next("");
  });
}
addHito(){

    let data = {fases:this.presupuesto.fases,presupuestoRestante:this.presupuesto.precioTotal-this.importeTotalHitos()};
    this.popupSE.openPopup(PopupHitoPagoCotizadorComponent,data);    
    this.popupSE
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValueFactura) {
        this.presupuesto.hitos_pago.push({
          concepto: value.hito.concepto,
          fecha: value.hito.fecha,
          importe: value.hito.importe,
          fase: value.hito.fase,
          idFase:null,
          proceso: value.hito.proceso,
          idProceso:null,
        });
        this._unsubscribeIndividual.next('');
      }
    });

}
animar = false;
posicionAnimacion(){
  const miElemento = document.querySelector('.fase.selected');
  let caja = miElemento.getBoundingClientRect();
  this.posicionYAnimacion = window.innerHeight - caja.y - 59;
  this.posicionXAnimacion = caja.x;
}
posicionYAnimacion = 0;
posicionXAnimacion = 0;
  constructor(
    private userSE:UserService,private notificaciones: NotificacionesService,private popupSE: PopupService,private loaderSE: LoaderService, protected presupuestoSE: PresupuestoService, protected activatedRoute: ActivatedRoute, private departamentoSE: DepartamentosService) {
    super();
  }

  importarHitosDePago(value){
    let hitosPago = [];
    value.hitos_pago.forEach(hito => {
      let hitoAdd = {
        concepto: hito.concepto,
        fecha: hito.fecha.split('T')[0],
        importe: hito.cantidad,
        fase: null,
        idFase:null,
        proceso: null,
        idProceso:null,
      };
      if(hito.fase!=null){
        hitoAdd.fase = hito.fase;
        hitoAdd.idFase = hito.fase.id;
      }
      if(hito.proceso!=null){
        hitoAdd.proceso = hito.proceso;
        hitoAdd.idProceso = hito.proceso.id;
      }
      hitosPago.push(hitoAdd);
    });
    this.presupuesto.hitos_pago = hitosPago;
  }
  gantt = false;
  fasesGantt = [];
  cargarPresupuesto() {
    this.presupuestoSE.getPresupuesto(this.presupuestoId);
    this.presupuestoSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.presupuesto = value;
        console.log(this.presupuesto);
        this.presupuesto.cliente = value.cliente.nombre;
        this.presupuesto.rentabilidad = (value.multiplicador -1 ) * 100;
        this.presupuesto.fecha_ini = this.presupuesto.fecha_ini.split('T')[0];
        this.presupuesto.fecha_fin = this.presupuesto.fecha_fin.split('T')[0];
        this.presupuesto.fases.forEach(fase => {
          fase.fechaInicio = fase.fecha_ini.split('T')[0];
          fase.fechaFin = fase.fecha_fin.split('T')[0];
          fase.idHito = fase.id;
          fase.procesos.forEach(proceso => {
            proceso.idHito = proceso.id;
            proceso.fecha_ini = proceso.fecha_ini.split('T')[0];
            proceso.fecha_fin = proceso.fecha_fin.split('T')[0];
            if (proceso.key_result == null) {
              proceso.kr = false;
            } else {
              proceso.kr = true;
              proceso.key_result.fecha = proceso.key_result.fecha.split('T')[0];
            }
            proceso.asignaciones.forEach(asignacion => {
              asignacion.empleado = asignacion.empleado.id;
              asignacion.perfil = asignacion.perfil.id;
            });
            proceso.sub_procesos.forEach(subproceso => {
              subproceso.asignaciones.forEach(asignacion => {
              asignacion.empleado = asignacion.empleado.id;
              asignacion.perfil = asignacion.perfil.id;
            });
            });
          });
        });
        this.importarHitosDePago(value);
        this.presupuestoPreparado = true;
        this.calcularCostes();
        console.log(value);
        this.presupuestoSE.clearResultIndividual();
        this._unsubscribeIndividual.next('');
      });
  }
  presupuestoId = null;

  getAllDepartamentosEmpresa(){
    this.departamentoSE.getDepartamentosByEmpresa();
    this.loaderSE.open();
    this.departamentoSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        value.forEach((element) => {
          if (!this.perfiles.includes(element)) {
            this.perfiles = [...this.perfiles, element];
          }
        });
        this.loaderSE.closeContador();
        this.departamentoSE.clearResult();
        this._unsubscribeIndividual.next('');
      });
  }
  
  getAllUsersByEmpresa() {
    this.userSE.getAllUsersByEmpresa(false);
    this.loaderSE.open();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual3))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loaderSE.closeContador();
        value.forEach((element) => {
          if (!this.empleados.includes(element)) {
            this.empleados = [...this.empleados, element];
          }
        });
        this.empleados.map((i) => { i.fullName = i.nombre + ' ' + i.apellidos; return i; });
        this.empleadosPreparados = true;
        this.calcularCostes();
        this._unsubscribeIndividual3.next('');
      });
  }

  empleadosPreparados = false;
  presupuestoPreparado = false;

  
  
  ngOnInit(): void {
    this.calcularAltura();
    this.calcularAnchosMeses();
    this.presupuestoId = this.activatedRoute.snapshot.params['id'];
    if (this.presupuestoId != null) {
      this.cargarPresupuesto();
    }
    this.getAllUsersByEmpresa();
    this.getAllDepartamentosEmpresa();
  }

  validarFase(){
    this.errorDatos = false;

    if(this.faseSelected.nombre == null || this.faseSelected.nombre == ''){
      this.errorDatos = true;
      this.notificaciones.showError("Falta el nombre de la fase");
    }
    
    if(this.faseSelected.fechaInicio == null || this.faseSelected.fechaInicio == ''){
      this.errorDatos = true;
      this.notificaciones.showError("Falta la fecha de inicio de la fase");
    }

    if(this.faseSelected.fechaFin == null || this.faseSelected.fechaFin == ''){
      this.errorDatos = true;
      this.notificaciones.showError("Falta la fecha de fin de la fase");
    }

    this.faseSelected.procesos.forEach(proceso => {
      if(this.validarProceso(proceso)){
        this.errorDatos = true;
      }
    });

    setTimeout(() => {
      this.errorDatos = false;
    }, 400);
  }
  validarAsignacion(asignacion){
    let error = false;

    if(asignacion.empleado == null || asignacion.empleado == ''){
      error = true;
      console.error("Falta el empleado");
    }
/*
    if(asignacion.perfil == null || asignacion.perfil == ''){
      error = true;
    }
*/
    if(asignacion.horas == null || asignacion.horas == 0){
      error = true;
      console.error("Falta la cantidad de horas");
    }

    return error;
  }
  validarProceso(proceso){
    let error = false;

    if(proceso.nombre == null || proceso.nombre == ''){
      error = true;
      console.error("Falta el nombre del proceso");
    }

    if(proceso.fecha_ini == null || proceso.fecha_ini == ''){
      error = true;
      console.error("Falta la fecha de inicio del proceso");
    }

    if(proceso.fecha_fin == null || proceso.fecha_fin == ''){
      error = true;
      console.error("Falta la fecha de fin del proceso");
    }

    if(proceso.horas == 0 || proceso.horas == null){
      error = true;
      console.error("Falta la cantidad de horas del proceso");
    }

    proceso.asignaciones.forEach(asignacion => {
      if(this.validarAsignacion(asignacion)){
        error = true;
      }
    });

    proceso.sub_procesos.forEach(subproceso => {
      if(this.validarSubproceso(subproceso)){
        error = true;
      }
    });

    return error;
  }

  validarSubproceso(subproceso){
    let error = false;

    if(subproceso.nombre == null || subproceso.nombre == ''){
      error = true;
      console.error("Falta el nombre del subproceso");
    }
    if(subproceso.horas == 0 || subproceso.horas == null){
      error = true;
      console.error("Falta la cantidad de horas del subproceso");
    }
    subproceso.asignaciones.forEach(asignacion => {
      if(this.validarAsignacion(asignacion)){
        error = true;
      }
    });

    return error;
  }

  errorDatos = false;
  guardarFase(){
    this.validarFase();
    if(!this.errorDatos){
      this.animacionGuardarFase();
    }
  }

  animacionGuardarFase(){
    this.posicionAnimacion();
    if(this.fasesOpened.includes(this.faseSelected)){
      this.controlOpenFases(this.faseSelected);
    }
    this.animar = true;
    this.faseSelected = null;
    setTimeout(() => {
      this.animar = false;
    }, 1000);      
    setTimeout(() => {
      this.moverGantt = true;      
      setTimeout(() => {
        this.moverGantt = false;
      }, 200);
    }, 650);
  }
  moverGantt = false;
  addFase() {
    this.presupuesto.fases.push({
      nombre: null,
      horas: null,
      fechaInicio: null,
      fechaFin: null,
      procesos: [],
      idHito: null
    });
    this.faseSelected = this.presupuesto.fases[this.presupuesto.fases.length - 1];
  }
  ganttListo = false;
  mostrarGant(evento,cabecera){
    evento.stopPropagation();
    console.log(evento);
    console.log(cabecera);
    if(cabecera && this.gantt){
      return;
    }
    this.calcularGantt();
    this.gantt= !this.gantt
  
  }
  calcularGantt(){
    this.ganttListo = false;
    this.fasesGantt = [];
    this.presupuesto.fases.forEach(fase => {
      let tareas = [];
      fase.procesos.forEach(proceso => {
        if(proceso.fecha_ini != null && proceso.fecha_fin != null){
          tareas.push({
            nombre: proceso.nombre,
            start: proceso.fecha_ini,
            end: proceso.fecha_fin,
            comprimida: false,
          });
          this.ganttListo = true;
        }
      });
      this.fasesGantt.push({
        tareas: tareas
      })
    });
  }
  removeFase(fase) {
    this.presupuesto.fases.splice(fase, 1);
  }
  removeProceso(fase, proceso) {
    fase.procesos.splice(proceso, 1);
    this.calcularHorasFase(fase);
  }
  addProceso(fase) {
    fase.procesos.push({
      nombre: null,
      fecha_ini: null,
      fecha_fin: null,
      importe: 0,
      horas: 0,
      horasImputadas: 0,
      kr: false,
      key_result: null,
      asignaciones: [],
      totalUnidadesProductivas: 0,
      sub_procesos : [],
      zindex: 2,
      idHito: null
    });
    !this.fasesOpened.includes(fase) ?  this.fasesOpened.push(fase) : null;

  }
  removeAsignacion(proceso, asignacion) {
    proceso.asignaciones.splice(asignacion, 1);
    this.elegirEmpleado(proceso);
    this.calcularHorasProceso(proceso);
  }
  addAsignacion(subProceso) {
    subProceso.asignaciones.push({
      empleado: null,
      horas: 0,
      perfil: null,
      coste: 0
    });
    this.procesoSelected.totalUnidadesProductivas = 0;
    this.procesoSelected.sub_procesos.map(element=>{
      this.procesoSelected.totalUnidadesProductivas += element.asignaciones.length;
    })
  }
  addSubprocesos(proceso){
    proceso.sub_procesos.push({
      nombre: null,
      horas: 0,
      horasImputadas: 0,
      asignaciones: [],
      zindex: 2,
    });
    !this.procesosOpened.includes(proceso) ?  this.procesosOpened.push(proceso) : null;

  }
  perfilesEmpleado(idEmpleado): { nombre: string; id: number; }[] {
    let id;
    if (typeof idEmpleado == 'number') {
      id = idEmpleado;
    } else {
      id = idEmpleado.id;
    }
    let emp = this.empleados.find(empleado => empleado.id == id);
    return emp.departamentos;
  }
  empleados = [];

  perfiles = [];

  elegirPerfil(proceso) {

  }
  comprobarAsignacion(asignacion) {
    let correcto = false;
    let perfiles = [];
    perfiles = this.perfilesEmpleado(asignacion.empleado);
    perfiles.forEach(val => {
      if (val.id == asignacion.perfil) {
        correcto = true;
      }
    }
    );
    if (!correcto) {
      asignacion.perfil = null;
    }
  }
  calcularCostes(){
    if(this.presupuestoPreparado && this.empleadosPreparados){
      this.presupuesto.fases.forEach(fase => {
        fase.procesos.forEach(proceso => {
          this.elegirEmpleado(proceso);
          proceso.sub_procesos.forEach(subProceso => {
            this.elegirEmpleado(subProceso);
          }
          );
        });
      });
      this.updateHours();
    }
  }
  elegirEmpleado(proceso) {
    proceso.importe = 0;
    console.log(proceso);
    proceso.asignaciones.forEach(asignacion => {
      console.log(asignacion);
      //obtener coste del empleado
      this.empleados.forEach(empleado => {
        if(empleado.id == asignacion.empleado){
          proceso.importe += asignacion.horas * empleado.coste;
          asignacion.empleado = empleado;
        }
      });
      /*let emp = this.empleados.find(empleado => empleado.id == asignacion.empleado);
      console.log(emp);
      proceso.importe += asignacion.horas * emp.coste;
      asignacion.empleado = emp;*/

    });
  }
  cambioKeyResult(proceso) {
    if (!proceso.kr) {
      proceso.key_result = null;
    } else {
      proceso.key_result = {
        nombre: null,
        fecha: null,
      };
    }
  }
  presupuesto = {
    fases:
      [
      ],
      hitos_pago: [],
    fecha_ini: '2023-01-01',
    fecha_fin: '2023-01-01',
    importeTotal: 0,
    nombre: '',
    cliente: '',
    costeDia: 0,
    horas: 0,
    dias: 0,
    precioTotal: 0,

    etiqueta: '#CCCCCC',
    costeIndirecto: null,
    abreviatura: 'P1',
    sector: 'Sector 1',
    contacto: 'Contacto 1',
    rentabilidad: 0
  }

  calcularHorasProceso(proceso) {
    let horas = 0;
    proceso.asignaciones.forEach(asignacion => {
      horas += asignacion.horas;
    });
    proceso.horas = horas;
  }
  calcularHorasFase(fase) {
    let horas = 0;
    fase.procesos.forEach(proceso => {
      horas += proceso.horas;
    });
    fase.horas = horas;
  }

  costeTotal() {
    let coste = 0;
    this.presupuesto.fases.forEach(fase => {
      fase.procesos.forEach(proceso => {
        coste += proceso.importe;
      });
    });
    return coste;
  }

  horasTotal() {
    let horas = 0;
    this.presupuesto.fases.forEach(fase => {
      horas += fase.horas;
    });
    return horas;
  }

  comprobarFechas() {
    let correcto = true;
    this.presupuesto.fases.forEach(fase => {
      fase.procesos.forEach(proceso => {
        if (proceso.fecha_ini == null || proceso.fecha_fin == null) {
          correcto = false;
        }
        if (proceso.fecha_ini > proceso.fecha_fin) {
          correcto = false;
        }
        if (proceso.kr) {
          if (proceso.key_result.fecha == null) {
            correcto = false;
          }
        }
      });
    });
    return correcto;
  }

  proyecto() {
    this.loaderSE.open();
    this.presupuestoSE.cerrarPresupuesto(this.presupuesto);
    this.presupuestoSE.getResultUpdate()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((val) => {
        this.loaderSE.closeContador();
        this.notificaciones.showFeedBack("Proyecto creado correctamente");
        console.log(val);
      }
      );
  }
  obtenerIdMasAlto(){
    let id = 0;
    this.presupuesto.fases.forEach(fase=>{
      if(fase.id>id){
        id = fase.id;
      }
      fase.procesos.forEach(proceso=>{
        if(proceso.id>id){
          id = proceso.id;
        }
      })
    })
    return id;
  }
  asignarIds(idInicial){
    let id = idInicial;
    this.presupuesto.fases.forEach(fase=>{
      fase.idHito = id;
      id++;
      fase.procesos.forEach(proceso=>{
        proceso.idHito = id;
        id++;
      })
    })
  }
  asignarIdsHitos(){
    this.presupuesto.hitos_pago.forEach(hito=>{
      if(hito.fase==null){return}
      hito.idFase = hito.fase.idHito;
      if(hito.proceso==null){return}
      hito.idProceso = hito.proceso.idHito;
    });

  }
  save() {
    
    this.asignarIds(this.obtenerIdMasAlto()+1);
    this.asignarIdsHitos();
    this.loaderSE.open();
    //buscar la fecha de inicio y fin del proyecto
    let fechaIni = null;
    let fechaFin = null;
    this.presupuesto.fases.forEach(fase => {
      if (fechaIni == null || fechaIni > fase.fecha_ini) {
        fechaIni = fase.fecha_ini;
      }
      if (fechaFin == null || fechaFin < fase.fecha_fin) {
        fechaFin = fase.fecha_fin;
      }
      fase.procesos.forEach(proceso => {
        if (fechaIni == null || fechaIni > proceso.fecha_ini) {
          fechaIni = proceso.fecha_ini;
        }
        if (fechaFin == null || fechaFin < proceso.fecha_fin) {
          fechaFin = proceso.fecha_fin;
        }
      });
    });
    this.presupuesto.fecha_ini = fechaIni;
    this.presupuesto.fecha_fin = fechaFin;

    //Coger las primeras 3 letras del nombre
    if (this.presupuesto.nombre.length > 3) {
      this.presupuesto.abreviatura = this.presupuesto.nombre.substring(0, 3);
    }else{
      this.presupuesto.abreviatura = this.presupuesto.nombre;
    }
    this.presupuesto.abreviatura = this.presupuesto.abreviatura.toUpperCase();

    this.presupuestoSE.addPresupuesto(this.presupuesto);
    this.presupuestoSE.getResultUpdate()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((val) => {
        if(val==null){return}
        this.loaderSE.closeContador();
        this._unsubscribeIndividual.next("");
        this.notificaciones.showFeedBack("Presupuesto creado correctamente");
      }
      );
      this.presupuestoSE.getResultUpdateError()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((val) => {
        if(val==null){return}
        this.loaderSE.closeContador();
        console.log(val.error.message);
        this.notificaciones.showError(val.error.message);
        this._unsubscribeIndividual.next("");
      }
      );

  }

  //NUEVO CODIGO

  dias: number | null = null;
  horas: number | null = null;
  costeDia: number | null = null;
  rentabilidad: number | null = null;
  presupuestoTotal: number | null = null;
  coste = 0

  faseSelected: any = null;
  minDate: Date = new Date()
  fasesOpened: Array<any> = []
  procesoSelected: any = null;
  procesosOpened: Array<any>= []
  subProcesoSelected: any = null;
  subProcesosOpened: Array<any>= []

  getDateFase(fechaInicio, fechaFin){
    if(fechaInicio != null){
      this.faseSelected["fechaInicio"] = this.convertirFechaEnString(fechaInicio.value);      
    }
    if(fechaFin != null){
      this.faseSelected["fechaFin"] = this.convertirFechaEnString(fechaFin.value);
    }
  }
  altura = '500px';
  //calcular la altura de la ventana
  calcularAltura(){
    let altura = window.innerHeight;
    this.altura = (altura - 60) + 'px';
    console.log(this.altura);
  }
  calcularAnchosMeses(){
    let ancho = window.innerWidth;
    let anchoGant = ancho- 72 - 64 - 64 - 100 - 25;
    this.ancho1Meses = anchoGant / 30;
    this.ancho3Meses = anchoGant / 90;
    this.ancho6Meses = anchoGant / 180;
    this.ancho12Meses = anchoGant / 365;
  }
  cambioTamDias(tam){
    switch(tam){
      case '12M':
        this.anchoDia = this.ancho12Meses;
        break;
      case '6M':
        this.anchoDia = this.ancho6Meses;
        break;
      case '3M':
        this.anchoDia = this.ancho3Meses;
        break;
      case '1M':
        this.anchoDia = this.ancho1Meses;
        break;
    }
  }
  tamanyosMeses = ['1M','3M', '6M', '12M'];
  ancho1Meses = 0;
  ancho3Meses = 0;
  ancho6Meses = 0;
  ancho12Meses = 0;
  anchoDia = 20;
  cambioAnchoDia(val){
    if(this.anchoDia + val > 2){
    this.anchoDia += val;
    }
  }
  cambio = 5;
  convertirFechaEnString(fecha: Date){
    let diaString = new Date(fecha).getDate().toString();
    let mesString;
    let dia = new Date(fecha).getDate();
    if(dia < 10){
      diaString = "0"+dia;
    }
    let mes = new Date(fecha).getMonth()+1;
    if(mes < 10){
      mesString = "0"+mes;
    }else{
      mesString = mes.toString();
    }
    return new Date(fecha).getFullYear()+"-"+ mesString+"-"+diaString
  }
  getDateProceso(fechaInicio, fechaFin){
    if(fechaInicio != null){
      this.procesoSelected["fecha_ini"] = this.convertirFechaEnString(fechaInicio.value);
    }
    if(fechaFin != null){
      this.procesoSelected["fecha_fin"] = this.convertirFechaEnString(fechaFin.value);
    }

  }
  abrirCalculadora(nombre:string, numero:number, fechaInicio: any, fechaFin: any, receptorHoras: any){
    this.popupSE.openPopup(CalculadoraTiempoEstimadoPopupComponent, {nombre: nombre, numero: numero, inicio: fechaInicio, fin: fechaFin});
    this.popupSE.returnData().pipe(takeUntil(this._unsubscribeIndividual4)).subscribe(res=>{
      if(res==null){return}
      console.log(res);
      receptorHoras.horas = res.returnValue;
      console.log(receptorHoras);
      this._unsubscribeIndividual4.next("");
    })
  }

  seleccionarFase(fase){
    if(this.faseSelected != fase){
      if(this.faseSelected == null){
        this.faseSelected = fase;
      }else{
        this.notificaciones.showError("No se puede seleccionar la fase", "No se puede seleccionar la fase porque no se ha guardado la fase anterior");
      }
    }
  }
  abrirSiHayProcesos(fase){
    if(fase == this.faseSelected){
      if(fase.procesos.length > 0){
        if(!this.fasesOpened.includes(fase)){
          this.fasesOpened.push(fase);
        }
      }
    }

  }
  controlOpenFases(fase){
    this.fasesOpened.includes(fase) ? this.fasesOpened.splice(this.fasesOpened.indexOf(fase), 1) : this.fasesOpened.push(fase)
  }
  eliminarFase(fase){
    this.popupSE.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: "¿Estas seguro que quieres eliminar la fase?",
    });
    this.popupSE.returnData().pipe(takeUntil(this._unsubscribeIndividual)).subscribe(res=>{
      if(res==null){return}
      if(res.returnValue){
        //buscar los hitos de pagos con esa fase y eliminarlos
        this.presupuesto.hitos_pago.forEach(hito => {
          if(hito.fase == fase){
            this.presupuesto.hitos_pago.splice(this.presupuesto.hitos_pago.indexOf(hito), 1);
          }
        });

        this.presupuesto.fases.splice(this.presupuesto.fases.indexOf(fase), 1);
        this.updateHours();
        this.updateCosteDia();
        this.notificaciones.showFeedBack("Se ha eliminado la fase correctamente");
        this.faseSelected = null;
      }
      this._unsubscribeIndividual.next("");
    })
  }
  controlOpenProceso(proceso){
    this.procesosOpened.includes(proceso) ? this.procesosOpened.splice(this.procesosOpened.indexOf(proceso), 1) : this.procesosOpened.push(proceso)
  }
  eliminarProceso(proceso, fase){
    this.popupSE.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: "¿Estas seguro que quieres eliminar el proceso?",
    });
    this.popupSE.returnData().pipe(takeUntil(this._unsubscribeIndividual2)).subscribe(res=>{
      if(res==null){return}
      if(res.returnValue){
        //buscar los hitos de pagos con esa fase y eliminarlos
        this.presupuesto.hitos_pago.forEach(hito => {
          if(hito.proceso == proceso){
            this.presupuesto.hitos_pago.splice(this.presupuesto.hitos_pago.indexOf(hito), 1);
          }
        });
        if (this.presupuesto.fases[this.presupuesto.fases.indexOf(fase)].procesos.length == 1) {
          this.presupuesto.fases[this.presupuesto.fases.indexOf(fase)].procesos.splice(this.presupuesto.fases[this.presupuesto.fases.indexOf(fase)].procesos.indexOf(proceso), 1);
          this.fasesOpened.splice(this.fasesOpened.indexOf(fase), 1)
        }else{
          this.presupuesto.fases[this.presupuesto.fases.indexOf(fase)].procesos.splice(this.presupuesto.fases[this.presupuesto.fases.indexOf(fase)].procesos.indexOf(proceso), 1);

        }
        this.updateHours()
        this.updateCosteDia()
        this.notificaciones.showFeedBack("Se ha eliminado el proceso correctamente")
      }
      this._unsubscribeIndividual2.next("");
    })
  }
  eliminarSubproceso(subProceso, proceso){
    this.popupSE.openPopup(PopupConfirmacionBorradorComponent, {
      descripcion: "¿Estas seguro que quieres eliminar el subproceso?",
    });
    this.popupSE.returnData().pipe(takeUntil(this._unsubscribeIndividual3)).subscribe(res=>{
      if(res==null){return}
      if(res.returnValue){
        if (proceso.sub_procesos.length == 1) {
          this.procesosOpened.splice(this.procesosOpened.indexOf(proceso), 1)
          proceso.sub_procesos.splice(proceso.sub_procesos.indexOf(subProceso), 1);
        }else{
          proceso.sub_procesos.splice(proceso.sub_procesos.indexOf(subProceso), 1);
        }
        this.procesoSelected.totalUnidadesProductivas = 0;
        this.procesoSelected.sub_procesos.map(element=>{
          this.procesoSelected.totalUnidadesProductivas += element.asignaciones.length;
        })
        this.updateHours()
        this.updateCosteDia()

        this.notificaciones.showFeedBack("Se ha eliminado el subproceso correctamente")
      }
      this._unsubscribeIndividual3.next("");
    })
  }
  controlOpenSubproceso(subProceso){
    if ( this.subProcesosOpened.includes(subProceso)) {
      this.subProcesosOpened.splice(this.subProcesosOpened.indexOf(subProceso), 1);
      this.subProcesoSelected = null;
    }else{
      this.subProcesosOpened.push(subProceso)
    }
  }

  updateHours(){
    this.calcularHoras();
    this.updateCosteDia();
    this.updateCosteDia();
  }
  
  opcionSeleccionada(event: string) {
    this.paso = event;
  }
  paso = 'Cotizador';
  calcularHoras(){
    this.presupuesto.horas = 0;
    this.presupuesto.fases.forEach(fase => {
      let horasFase = 0;
      fase.procesos.forEach(proceso => {
        let horasProceso = 0;        
        proceso.asignaciones.forEach(asignacion => {
          horasProceso += asignacion.horas;
        });
        proceso.sub_procesos.forEach(subProceso => {
          let horasSubProceso = 0;
          subProceso.asignaciones.forEach(asignacion => {
            horasSubProceso += asignacion.horas;
          });
          subProceso.horasImputadas = horasSubProceso;
          horasProceso += horasSubProceso;
        });
        proceso.horasImputadas = horasProceso;
        horasFase += horasProceso;
      });
      fase.horas = horasFase;
      this.presupuesto.horas += horasFase;
    });
    this.presupuesto.dias = this.presupuesto.horas / 8;
  }
  updateCosteDia(){
    this.presupuesto.costeDia = 0;
    this.coste = 0

    this.presupuesto.fases.map(fase=>{
      fase.procesos.map(proceso=>{
        proceso.asignaciones.map(asignacion=>{
          if (asignacion.empleado != null) {
              this.coste += (asignacion.empleado.coste * asignacion.horas)
          }
        })
        proceso.sub_procesos.map(subProceso=>{
          subProceso.asignaciones.map(asignacion=>{
            if (asignacion.empleado != null) {
                this.coste += (asignacion.empleado.coste * asignacion.horas)
            }
          })
        })
      })
    })
    if (this.coste == 0) {
      this.presupuesto.costeDia = 0;
      this.presupuesto.precioTotal = 0;
    }else{
      this.presupuesto.costeDia = this.coste/this.presupuesto.dias;
      this.presupuesto.precioTotal = Number(this.coste) + (Number(this.coste) * Number((this.presupuesto.rentabilidad / 100)));
    }

  }

  cambiarZindex(elemento,proceso=false){
    if (proceso) {
      elemento.zindex = 3;
      elemento.sub_procesos.forEach(element => {
        element.zindex = 2;
      });
    }else{
      elemento.zindex = 3;
    }
  }

  comprobarSeleccionadoSubprocesos(proceso){
    console.log(proceso);
    let res = 2;
    proceso.sub_procesos.forEach(proceso => {
      if(proceso.zindex > 2){
        res = 3;
      }
    });
    return res;
  }

  eliminarAsignacion(asignacion, proceso){
    proceso.asignaciones.splice(proceso.asignaciones.indexOf(asignacion), 1);
    this.updateHours();
  }
  
}


