<div class="generalContainer">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]="'cotizador'"></sidebar>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataHeaderContainer">
      <app-header-bar titulo="Cotizador" [cerrar]="false"></app-header-bar>
    </div>
    <div class="propuestaContainer">
      
      <div class="titleContainer">

        <input type="text" [(ngModel)]="presupuesto.nombre" placeholder="🚀   Propuesta sin título">
      </div>
      <div class="dataContainer">
        <div class="data">


          <div class="customContainer">
            <label for="">Días</label>
            <span *ngIf="presupuesto.dias == null">
              -
            </span>
            <span *ngIf="presupuesto.dias != null">
              {{presupuesto.dias}}
            </span>
          </div>
          <div class="customContainer">
            <label for="">Horas</label>
            <span *ngIf="presupuesto.horas == null">
              -
            </span>
            <span *ngIf="presupuesto.horas != null">
              {{presupuesto.horas}}
            </span>
          </div>
          <div class="customContainer">
            <label for="">Coste/día</label>
            <span *ngIf="presupuesto.costeDia == null">
              -
            </span>
            <span *ngIf="presupuesto.costeDia != null">
              {{presupuesto.costeDia | currency:'EUR'}}
            </span>
          </div>
          <div class="customContainer especial">
            <label for="">Rentabilidad</label>
            <span *ngIf="presupuesto.rentabilidad == null">
              - %
            </span>
            <span *ngIf="presupuesto.rentabilidad != null">
              {{presupuesto.rentabilidad}} %
            </span>
            <input type="number" [(ngModel)]="presupuesto.rentabilidad" (input) = updateCosteDia()>
          </div>
          <div class="customContainer">
            <label for="">Presupuesto total</label>
            <span *ngIf="presupuesto.precioTotal == null">
              -
            </span>
            <span *ngIf="presupuesto.precioTotal != null">
              {{presupuesto.precioTotal | currency:'EUR'}}
            </span>
          </div>
          <div class="customContainer especial">
            <label for="">Cliente</label>
            <span *ngIf="presupuesto.cliente == ''">
              -
            </span>
            <span *ngIf="presupuesto.cliente != ''">
              {{presupuesto.cliente}}
            </span>
            <input type="text" [(ngModel)]="presupuesto.cliente">
          </div>
        </div>
        <menu-navegacion
        [opcionPorDefecto]="'Cotizador'"
        [opciones]="['Cotizador','Pagos']"
        [redondo]="true"
        (emitOpcionSeleccionada)="opcionSeleccionada($event)"
      ></menu-navegacion>
        <button [class.disabled]="presupuesto.fases.length == 0"
        *ngIf="presupuestoId==null" (click)="save()">Generar presupuesto</button>
        <button [class.disabled]="presupuesto.fases.length == 0"
          *ngIf="presupuestoId!=null" (click)="proyecto()">Generar proyecto</button>
      </div>
    </div>

    <ng-container *ngIf="paso=='Cotizador'">
      <div class="contenedorFases">
    <p class="noData" *ngIf="presupuesto.fases.length == 0">
      No hay fases disponibles
    </p>
    <div class="fases">
      <div class="fase" *ngFor="let fase of presupuesto.fases; let iFase = index" class="fase"
        [class.selected]="faseSelected == fase" [class.openedFase]="fasesOpened.includes(fase)" (click)="seleccionarFase(fase); abrirSiHayProcesos(fase)">
        <div class="header">
          <div class="data">
            <div class="left">
              <span [class.animar]="animar" [style.left]="posicionXAnimacion+'px'" [style.bottom]="posicionYAnimacion+'px'" [style.display]="!animar?'none':'block'" class="animacion"></span>
            <button class="addProceso" (click)="addProceso(fase)">
              <mat-icon>add</mat-icon>
              <span>Añadir proceso</span>
            </button>
            <span class="cantidadProcesos">
              x{{fase.procesos.length}}
              <img src="/assets/icons/cantidadProcesosIcon.svg" alt="">
            </span>
            <div class="nombreFase">
              <img src="/assets/icons/procesosIcon.svg" alt="">
              <input [disabled]="faseSelected != fase" type="text" [(ngModel)]="fase.nombre" placeholder="Fase sin nombre">
            </div>
          </div>
          <div class="right">
            <div class="dateContainer">
              <mat-date-range-input [disabled]="faseSelected != fase" [rangePicker]="picker" [min]="minDate">
                <input matStartDate placeholder="DD/MM/YYYY" [(ngModel)]="fase.fechaInicio" (dateChange)="getDateFase($event, null)">
                <input matEndDate placeholder="DD/MM/YYYY" [(ngModel)]="fase.fechaFin" (dateChange)="getDateFase(null,$event)">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker" (click)="seleccionarFase(fase)">
                <img src="/assets/icons/calendarioIcon.svg" alt="" matDatepickerToggleIcon>
              </mat-datepicker-toggle>
              <mat-date-range-picker [ngStyle]="{'display': 'none'}" #picker></mat-date-range-picker>
            </div>
            <!-- <button class="calculadora" (click)="abrirCalculadora(fase.nombre)" *ngIf="false">
              <img src="/assets/icons/calculadora.svg" alt="">
            </button> -->
            <div class="horasFase">
              <span *ngIf="fase.horas == null">
                0 horas
              </span>
              <span *ngIf="fase.horas != null">
                {{fase.horas}} horas
              </span>
            </div>
          </div>


          </div>
          <div class="buttonsContainer" [class.openedFase]="fasesOpened.includes(fase)" >
            <button (click)="eliminarFase(fase)">
              <img src="/assets/icons/borrarIcon.svg" alt="">
            </button>
            <!--<button (click)="$event.stopPropagation();controlOpenFases(fase)"  [class.disabled]="!fase['procesos'] || fase['procesos'].length == 0">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>-->
          </div>
        </div>
        <div class="procesos" >
          <div class="proceso" *ngFor="let proceso of fase.procesos; let i = index;" [class.openedProceso]="procesosOpened.includes(proceso)">
            <img src="/assets/icons/lineaProceso.svg" alt="">
            <div class="data" [class.selected]="procesoSelected == proceso" (click)="procesoSelected = proceso;" [class.openedProceso]="procesosOpened.includes(proceso)">
              <div class="header">
                <div class="left">
                  <button *ngIf=" proceso.asignaciones.length == 0" (click)="addSubprocesos(proceso)">
                    <mat-icon>add</mat-icon>
                    <span>Añadir subproceso</span>
                  </button>
                  <img src="/assets/icons/cantidadProcesosIcon.svg" alt="">
                  <ng-container *ngIf="(i+1) < 10">
                    0{{i+1}}.
                  </ng-container>
                  <ng-container *ngIf="(i+1) >= 10">
                    {{i+1}}.
                  </ng-container>
                  <input type="text" [(ngModel)]="proceso.nombre" placeholder="Proceso sin nombre">
                </div>
                <div class="right">
                  <span class="trabajadores">
                    <img src="/assets/icons/trabajdorIcon.svg" alt="">
                    x{{proceso.totalUnidadesProductivas}}
                  </span>
                  <div class="dateContainer" [class.disabled]="fase.fechaInicio == null && fase.fechaFin == null">
                    <mat-date-range-input [rangePicker]="picker" [min]="fase.fechaInicio" [max]="fase.fechaFin">
                      <input matStartDate placeholder="DD/MM/YYYY" [(ngModel)]="proceso.fecha_ini" (dateChange)="getDateProceso($event, null)">
                      <input matEndDate placeholder="DD/MM/YYYY" [(ngModel)]="proceso.fecha_fin" (dateChange)="getDateProceso(null,$event)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker" (click)="procesoSelected = proceso">
                      <img src="/assets/icons/calendarioIcon.svg" alt="" matDatepickerToggleIcon>
                    </mat-datepicker-toggle>
                    <mat-date-range-picker [ngStyle]="{'display': 'none'}" #picker></mat-date-range-picker>
                  </div>
                  <button class="calculadora" (click)="procesoSelected = proceso;abrirCalculadora(proceso.nombre, (i+1), proceso.fecha_fin, proceso.fecha_fin,proceso)" >
                    <img src="/assets/icons/calculadora.svg" alt="">
                  </button>
                  <div class="horasProceso">
                    <span>
                      {{proceso.horasImputadas|number:'0.0-2'}}/{{proceso.horas|number:'0.0-2'}} horas
                    </span>
                  </div>
                  <div class="buttonsContainer" [class.openedProceso]="procesosOpened.includes(proceso)">
                    <button (click)="eliminarProceso(proceso, fase)">
                      <img src="/assets/icons/borrarIcon.svg" alt="">
                    </button>
                    <button (click)="$event.stopPropagation();controlOpenProceso(proceso)"  [class.disabled]="proceso.sub_procesos.length == 0">
                      <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="proceso.sub_procesos.length == 0" class="unidadesProductivas" (click)="$event.stopPropagation()" ><!--[style.z-index]="proceso.zindex">-->
                <div class="unidadProductiva" *ngFor="let asignacion of proceso.asignaciones" (click)="$event.stopPropagation()">
                  <img src="/assets/icons/unidadProductivaIcon.svg" alt="">
                  <div class="data">
                    <div class="left">
                      <span>
                        <img src="/assets/icons/trabajdorIcon.svg" alt="">
                      </span>
                      <ng-select (focus)="cambiarZindex(proceso,true)" (change)="updateCosteDia()" class="cotizadorSelector" [clearable]="false" [items]="empleados" placeholder="Empleado" bindLabel="fullName" [(ngModel)]="asignacion.empleado" ></ng-select>
                    </div>
                    <div class="right">
                      <input type="number" min="0" [(ngModel)]="asignacion.horas" placeholder="Horas" (input)="updateHours()" [class.disabled]="asignacion.empleado == null">
                    </div>
                  </div>
                  <mat-icon (click)="eliminarAsignacion(asignacion, proceso)">close</mat-icon>
                </div>
                <button (click)="$event.stopPropagation(); addAsignacion(proceso)">
                  <mat-icon>add_circle</mat-icon>
                  Añadir recurso
                </button>
              </div>
              <div class="subProcesos" ><!--[style.z-index]="comprobarSeleccionadoSubprocesos(proceso)">-->
                <div class="subProceso" *ngFor="let subProceso of proceso.sub_procesos; let z = index;">
                  <img src="/assets/icons/subProcesoIcon.svg" alt="">
                  <div class="data" [class.opened]="subProcesosOpened.includes(subProceso)" (click)="subProcesoSelected = subProceso;controlOpenSubproceso(subProceso);">
                    <div class="header">
                      <div class="left">
                        <span class="codigoSubproceso" [class.selected]="subProcesosOpened.includes(subProceso)">
                          <ng-container *ngIf="(i+1) < 10">
                            #0{{i+1}}.{{z + 1}}.
                          </ng-container>
                          <ng-container *ngIf="(i+1) >= 10">
                            #{{i+1}}.{{z + 1}}.
                          </ng-container>
                        </span>
                        <input type="text" [(ngModel)]="subProceso.nombre" placeholder="Subproceso sin nombre">
                      </div>
                      <div class="right">
                        <span class="trabajadores">
                          <img src="/assets/icons/trabajdorIcon.svg" alt="">
                          x{{subProceso.asignaciones.length}}
                        </span>
                        
                        <div class="horasSubproceso">
                          <button class="calculadora" (click)="procesoSelected = proceso;abrirCalculadora(proceso.nombre, (i+1), proceso.fecha_fin, proceso.fecha_fin,subProceso)" >
                            <img src="/assets/icons/calculadora.svg" alt="">
                          </button>
                          <span>
                            {{subProceso.horasImputadas|number:'0.0-2'}}/{{subProceso.horas|number:'0.0-2'}} horas
                          </span>
                        </div>
                        <mat-icon (click)="eliminarSubproceso(subProceso, proceso)">close</mat-icon>
                      </div>
                    </div>
                    <div class="unidadesProductivas" (click)="$event.stopPropagation()">
                      <div class="unidadProductiva" *ngFor="let asignacion of subProceso.asignaciones" (click)="$event.stopPropagation()">
                        <img src="/assets/icons/unidadProductivaIcon.svg" alt="">
                        <div class="data">
                          <div class="left">
                            <span>
                              <img src="/assets/icons/trabajdorIcon.svg" alt="">
                            </span>
                            <ng-select (focus)="cambiarZindex(subProceso)" (change)="updateCosteDia()" class="cotizadorSelector" [clearable]="false" [items]="empleados" placeholder="Empleado" bindLabel="fullName" [(ngModel)]="asignacion.empleado" ></ng-select>
                          </div>
                          <div class="right">
                            <input type="number" min="0" [(ngModel)]="asignacion.horas" placeholder="Horas" (input)="updateHours()" [class.disabled]="asignacion.empleado == null">
                          </div>
                        </div>
                        <mat-icon (click)="eliminarAsignacion(asignacion, subProceso)">close</mat-icon>
                      </div>
                      <button (click)="$event.stopPropagation(); addAsignacion(subProceso)">
                        <mat-icon>add_circle</mat-icon>
                        Añadir recurso
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="faseSelected == null" class="newFase" (click)="addFase()">
      <mat-icon>add_circle_outline</mat-icon>
      Añadir una nueva fase
    </button>
    <button *ngIf="faseSelected != null" (click)="guardarFase()" class="newFase" [class.error]="errorDatos">
      <mat-icon>save</mat-icon>
      Guardar Fase
    </button>
  </div>
    <div class="gantt" [class.opened]="gantt" [class.anim]="moverGantt" (click)="mostrarGant($event,true)">
      <span class="circulo" (click)="mostrarGant($event,false)">
        <span class="flecha"></span>
      </span>
      <div class="zoom">
        <p>Vista</p><menu-navegacion [opcionPorDefecto]="'12M'" [opciones]="tamanyosMeses" (emitOpcionSeleccionada)="cambioTamDias($event)"></menu-navegacion>
      
      </div>
      <gantt *ngIf="ganttListo" [height]="altura" [anchoDia]="anchoDia" [fases]="fasesGantt"></gantt>
      <p *ngIf="!ganttListo">Para visualizar el Gantt primero debes crear fases y procesos</p>
    </div>
  </ng-container>
  <ng-container *ngIf="paso=='Pagos'">
<div class="contenedorHitos">
  <div class="cabecera">
    <p class="label">Restante a desembolsar</p>
    <p class="precios">{{importeTotalHitos() | currency:'EUR'}} / {{presupuesto.precioTotal | currency:'EUR'}}</p>
    
    <button (click)="addHito()" class="newHito">
      <mat-icon>add_circle_outline</mat-icon>
      Añadir hito de pago
    </button>
  </div>
  <div class="cuerpo">
    <div class="contenedorHitoPago" *ngFor="let hito of presupuesto.hitos_pago">
      <div class="hitoPago">
        <div class="fecha">
          <span class="icon"><img src="/assets/icons/finish.png"></span>
          <span class="date"><ng-container *ngIf="hito.fase == null">{{hito.fecha|date:'d \'d\'e MMMM y'}}</ng-container><ng-container *ngIf="hito.fase != null">{{hito.fase.fechaFin|date:'d \'d\'e MMMM y'}}</ng-container></span>
        </div>
        <div class="contenedor">
          <div class="precio">{{hito.importe|currency:'EUR'}}</div>
          <div class="fase"><ng-container *ngIf="hito.fase == null">{{hito.concepto}}</ng-container><ng-container *ngIf="hito.fase != null && hito.proceso==null">{{hito.fase.nombre}}: ({{hito.concepto}})</ng-container><ng-container *ngIf="hito.proceso != null">{{hito.proceso.nombre}}: ({{hito.concepto}})</ng-container></div>
          <div class="accion"><img (click)="eliminarHito(hito)" src="/assets/icons/borrarIcon.svg" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</div>
    </ng-container>

    <div class="fondoBlur" (click)="mostrarGant($event,false)"></div>
  </div>
