<div class="dashboardGeneralContainer">
    <div class="dataBodyContainer">
        <div class="grafico">
            <p>Costes de {{meses[month-1]}}</p>
            <grafico-pie [valores]="valoresGrafico" [colores]="coloresGrafico" [unidad]="' €'" [width]="'250'"></grafico-pie>
        </div>
        <div class="seccionCostes">
            <div class="infoCabecera"><p>Costes Variables</p><span>{{totalVariable|currency:'EUR'}}</span></div>
            <div class="tabla">
                <ng-container *ngFor="let item of costesVariables;let i = index">
                    <div (click)="abrirCoste(item)" [class.par]="i%2==0" [class.hijos]="item.interior.length>0" [class.abierto]="item.abierto">
                        <div *ngIf="item.familia != null">{{item.familia.nombre}}</div>
                        <div *ngIf="item.familia == null">{{item.concepto}}</div>
                        <div>{{item.cantidad | currency:'EUR'}}</div>
                    </div>
                    <div class="hijosContainer" *ngIf="item.interior.length>0" [class.abierto]="item.abierto">
                        <ng-container *ngFor="let hijo of item.interior">
                            <div>
                                <div>{{hijo.concepto}}</div>
                                <div>{{hijo.cantidad | currency:'EUR'}}</div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="seccionCostes">
            <div class="infoCabecera"><p>Costes Fijos</p><span>{{totalFijo|currency:'EUR'}}</span></div>
            <div class="tabla">
                <ng-container *ngFor="let item of costesFijos;let i = index">
                    <div (click)="abrirCoste(item)" [class.par]="i%2==0" [class.hijos]="item.interior.length>0" [class.abierto]="item.abierto">
                        <div *ngIf="item.familia != null">{{item.familia.nombre}}</div>
                        <div *ngIf="item.familia == null">{{item.concepto}}</div>
                        <div>{{item.cantidad | currency:'EUR'}}</div>
                    </div>
                    <div class="hijosContainer" *ngIf="item.interior.length>0" [class.abierto]="item.abierto">
                        <ng-container *ngFor="let hijo of item.interior">
                            <div>
                                <div>{{hijo.concepto}}</div>
                                <div>{{hijo.cantidad | currency:'EUR'}}</div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="seccionCostes">
            <div class="infoCabecera"><p>Costes Periodo</p><span>{{totalPeriodico|currency:'EUR'}}</span></div>
            <div class="tabla">
                <ng-container *ngFor="let item of costesPeriodicos;let i = index">
                    <div (click)="abrirCoste(item)" [class.par]="i%2==0" [class.hijos]="item.interior.length>0" [class.abierto]="item.abierto">
                        <div *ngIf="item.familia != null">{{item.familia.nombre}}</div>
                        <div *ngIf="item.familia == null">{{item.concepto}}</div>
                        <div>{{item.cantidad | currency:'EUR'}}</div>
                    </div>
                    <div class="hijosContainer" *ngIf="item.interior.length>0" [class.abierto]="item.abierto">
                        <ng-container *ngFor="let hijo of item.interior">
                            <div>
                                <div>{{hijo.concepto}}</div>
                                <div>{{hijo.cantidad | currency:'EUR'}}</div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>