import { APP_INITIALIZER, LOCALE_ID, ErrorHandler, NgModule } from '@angular/core';
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageCropperModule } from 'ngx-image-cropper';

import {MatPaginatorModule} from '@angular/material/paginator';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCheckboxModule} from '@angular/material/checkbox';


import { FullCalendarModule } from '@fullcalendar/angular';

//COMPONENTS
import { LoginComponent } from './Pages/login/login.component';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { AddImplicacionComponent } from './Pages/addImplicacion/addImplicacion.component';
import { AjustesComponent } from './Pages/ajustes/ajustes.component';
import { SidebarComponent } from './Components/sidebar/sidebar.component';
import { ProyectosComponent } from './Pages/proyectos/proyectos.component';
import { EquipoComponent } from './Pages/equipo/equipo.component';
import { TesoreriaComponent } from './Pages/tesoreria/tesoreria.component';
import { HeaderBarComponent } from './Components/header-bar/header-bar.component';
import { ModalImageComponent } from './Components/modal-image/modal-image.component';
import { PopupConfirmacionCerrarSesionComponent } from './Components/popup-confirmacion-cerrar-sesion/popup-confirmacion-cerrar-sesion.component';
import { PopupFacturaComponent } from './Components/popup-factura/popup-factura.component';
import { PopupConfirmacionBorradorComponent } from './Components/popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { MenuNavegacionComponent } from './Components/menu-navegacion/menu-navegacion.component';
import { TablaComponent } from './Components/tabla/tabla.component';
import { PopupGeneralProcesosComponent } from './Components/popup-general-procesos/popup-general-procesos.component';
import { PopupInformacionProyectoComponent } from './Components/popup-informacion-proyecto/popup-informacion-proyecto.component';
import { PopupInformacionEmpleadoComponent } from './Components/popup-informacion-empleado/popup-informacion-empleado.component';
import { NuevoIntegranteComponent } from './Pages/equipo/Nuevo-Integrante/Nuevo-Integrante.component';
import { NuevoProyectoComponent } from './Pages/proyectos/Nuevo-Proyecto/Nuevo-Proyecto.component';
import { PopupAddHitoPagoComponent } from './Components/popup-add-hitoPago/popup-add-hitoPago.component';
import { IconoCargarComponent } from './Components/iconoCargar/iconoCargar.component';
import { LoadingComponent } from './Components/loading/loading.component';

//SERVICIOS
import { LoginService } from './Services/login.service';
import { PopupService } from './Services/popup.service';
import { LoaderService } from './Services/loader.service';
import { NotificacionesService } from './Services/notificaciones.service';
import { ProyectosService } from './Services/proyectos.service';
import { DepartamentosService } from './Services/departamentos.service';
import { DefaultBaseService } from './Services/DefaultBase.service';
import { ProcesosService } from './Services/procesos.service';
import { FinanzasService } from './Services/finanzas.service';

//PIPE
import { HelloPipe } from './Pipes/hello.pipe';

//MODULOS
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSliderModule} from '@angular/material/slider';
import {
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';



import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { DailyComponent } from './Pages/daily/daily.component';
import { VacacionesComponent } from './Pages/vacaciones/vacaciones.component';
import { PopupAddDailyComponent } from './Components/popup-add-daily/popup-add-daily.component';
import { PopupEditDailyComponent } from './Components/popup-edit-daily/popup-edit-daily.component';
import { ClienteComponent } from './Pages/cliente/cliente.component';
import { PopupAddClienteComponent } from './Components/popup-add-cliente/popup-add-cliente.component';
import { CrmComponent } from './Pages/cliente/crm/crm.component';
import { CrmContabilidadPipe } from './Pipes/crm-contabilidad.pipe';
import { TarjetaInfoComponent } from './Components/tarjeta-info/tarjeta-info.component';
import { TarjetaProyectoComponent } from './Components/tarjeta-proyecto/tarjeta-proyecto.component';
import { PopUpCRMComponent } from './Components/pop-up-crm/pop-up-crm.component';
import { TarjetaCRMComponent } from './Components/tarjeta-crm/tarjeta-crm.component';
import { PopUpFacturaComponent } from './Components/pop-up-factura/pop-up-factura.component';
import { HitosComponent } from './Pages/tesoreria/hitos/hitos.component';
import { TotalHitosClientePipe } from './Pipes/total-hitos-cliente.pipe';
import { SumatorioPipe } from './Pipes/sumatorio.pipe';
import { PopUpInformacionFacturaComponent } from './Components/pop-up-informacion-factura/pop-up-informacion-factura.component';
import { PopUpPresupuestoComponent } from './Components/pop-up-presupuesto/pop-up-presupuesto.component';
import { DailyComponentComponent } from './Components/daily-component/daily-component.component';
import { EmpresaComponent } from './Pages/empresa/empresa.component';
import { GraficoPieComponent } from './Components/grafico-pie/grafico-pie.component';
import { ValidarDailyComponent } from './Components/validar-daily/validar-daily.component';
import { WeeklyProyectoComponent } from './Components/weekly-proyecto/weekly-proyecto.component';
import { CostesComponent } from './Pages/tesoreria/costes/costes.component';
import { CosteMesComponent } from './Pages/tesoreria/costes/coste-mes/coste-mes.component';
import { PopUpCosteComponent } from './Components/pop-up-coste/pop-up-coste.component';
import { EstadoProyectoComponent } from './Components/estado-proyecto/estado-proyecto.component';
import { GraficoVelocimetroComponent } from './Components/grafico-velocimetro/grafico-velocimetro.component';
import { PresupuestosComponent } from './Pages/proyectos/presupuestos/presupuestos.component';
import { NuevoPresupuestoComponent } from './Pages/proyectos/nuevo-presupuesto/nuevo-presupuesto.component';
import { GanttComponent } from './Components/gantt/gantt.component';
import { TareaComponent } from './Components/gantt/tarea/tarea.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './Adapters/CustomDateAdapter';
import { CalculadoraTiempoEstimadoPopupComponent } from './Components/CalculadoraTiempoEstimadoPopup/CalculadoraTiempoEstimadoPopup.component';
import { WeeklyComponent } from './Pages/weekly/weekly.component';
import { WeeklyInfoProyectoComponent } from './Components/weekly-info-proyecto/weekly-info-proyecto.component';
import { PagoComponent } from './Components/pago/pago.component';
import { VistaCostesMesComponent } from './Components/vista-costes-mes/vista-costes-mes.component';
import { PopupHitoPagoCotizadorComponent } from './Components/popup-hito-pago-cotizador/popup-hito-pago-cotizador.component';
import { ProyectoLineaComponent } from './Components/proyecto-linea/proyecto-linea.component';



registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AddImplicacionComponent,
    AjustesComponent,
    SidebarComponent,
    ProyectosComponent,
    EquipoComponent,
    TesoreriaComponent,
    HeaderBarComponent,
    ModalImageComponent,
    PopupConfirmacionCerrarSesionComponent,
    PopupFacturaComponent,
    PopupConfirmacionBorradorComponent,
    MenuNavegacionComponent,
    TablaComponent,
    PopupGeneralProcesosComponent,
    PopupInformacionProyectoComponent,
    PopupInformacionEmpleadoComponent,
    NuevoIntegranteComponent,
    NuevoProyectoComponent,
    PopupAddHitoPagoComponent,
    IconoCargarComponent,
    LoadingComponent,
    HelloPipe,
    DailyComponent,
    VacacionesComponent,
    PopupAddDailyComponent,
    PopupEditDailyComponent,
    ClienteComponent,
    PopupAddClienteComponent,
    CrmComponent,
    CrmContabilidadPipe,
    TarjetaInfoComponent,
    TarjetaProyectoComponent,
    PopUpCRMComponent,
    TarjetaCRMComponent,
    PopUpFacturaComponent,
    HitosComponent,
    TotalHitosClientePipe,
    SumatorioPipe,
    PopUpInformacionFacturaComponent,
    PopUpPresupuestoComponent,
    DailyComponentComponent,
    EmpresaComponent,
    GraficoPieComponent,
    ValidarDailyComponent,
    WeeklyProyectoComponent,
    CostesComponent,
    CosteMesComponent,
    PopUpCosteComponent,
    EstadoProyectoComponent,
    GraficoVelocimetroComponent,
    PresupuestosComponent,
    NuevoPresupuestoComponent,
    GanttComponent,
    TareaComponent,
    CalculadoraTiempoEstimadoPopupComponent,
    WeeklyComponent,
    WeeklyInfoProyectoComponent,
    PagoComponent,
    VistaCostesMesComponent,
    PopupHitoPagoCotizadorComponent,
    ProyectoLineaComponent,
  ],
  imports: [
    FullCalendarModule,
    MatPaginatorModule,
    DragDropModule,
    MatCheckboxModule,
    ImageCropperModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    NgSelectModule,
    MatDialogModule,
    HttpClientModule,
    MatSliderModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule
    // MatDialogConfig,
    // MatDialogRef
  ],
  providers: [
    LoginService,
    PopupService,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: DateAdapter, useClass: CustomDateAdapter },

    LoaderService,
    NotificacionesService,
    DefaultBaseService,
    ProyectosService,
    DepartamentosService,
    ProcesosService,
    FinanzasService,
  ],
  exports: [HelloPipe, CrmContabilidadPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
