<div class="dashboardGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'costes'"></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Costes"></app-header-bar>
      </div>
      <div class="dataBodyContainer">
        <div class="selectorFecha">
          <button (click)="anyoSeleccionado=anyoSeleccionado-1;cambiarAnyo()" [disabled]="anyoActual-1>=anyoSeleccionado" class="flecha izq"></button>
          <span>{{anyoSeleccionado}}</span>
          <button (click)="anyoSeleccionado=anyoSeleccionado+1;cambiarAnyo()" [disabled]="anyoActual+1<=anyoSeleccionado" class="flecha der"></button>
        </div>
        <div class="graficos">
          <coste-mes *ngIf="anyoSeleccionado != null && mesSeleccionado!=null && !actualizando" [year]="anyoSeleccionado" [month]="mesSeleccionado"></coste-mes>
        <menu-navegacion
          *ngIf="anyoSeleccionado != null && mesSeleccionado==null && !actualizando"
          [opcionPorDefecto]="'Totales'"
          [opciones]="['Totales','Categorizados']"
          [condensed]="true"
          (emitOpcionSeleccionada)="opcionSeleccionada($event)"
        ></menu-navegacion>

          <apx-chart
          *ngIf="anyoSeleccionado != null && mesSeleccionado==null && !actualizando"
            #chart
            [stroke]="chartOptions.stroke"
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [xaxis]="chartOptions.xaxis"
            [colors]="chartOptions.colors"
            [plotOptions]="chartOptions.plotOptions"
            [dataLabels]="chartOptions.dataLabels"
            [tooltip]="{ enabled: false }"
            [yaxis]="chartOptions.yaxis"
            [legend]="chartOptions.legend"
          ></apx-chart>
        </div>
        <div class="tabla">
          <div class="cabecera">
            <div class="titulo">Histórico meses</div>
            <div>Costes totales</div>
            <div>Costes fijos</div>
            <div>Costes variables</div>
            <div>Costes periodo</div>
            <div></div>
        </div>
        <div (click)="seleccionarMes(i+1)" class="linea" *ngFor="let mes of meses; let i = index" [class.seleccionado]="mesSeleccionado == i+1">
            <div class="titulo">{{mes.nombre}} <ng-container *ngIf="i == mesActual && anyoSeleccionado == anyoActual">· <span>mes actual</span></ng-container></div>
            <div>{{mes.Fijo+mes.Variable+mes.Periodo | currency:'EUR'}}</div>
            <div>{{mes.Fijo| currency:'EUR'}}</div>
            <div>{{mes.Variable| currency:'EUR'}}</div>
            <div>{{mes.Periodo| currency:'EUR'}}</div>
            <div><button class="btn" (click)="abrirPopUp(i+1,$event)">Añadir coste</button></div>
        </div>

      </div> 
      </div>
    </div>
  </div>
  