import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PopupInformacionProyectoComponent } from './Components/popup-informacion-proyecto/popup-informacion-proyecto.component';
import { AddImplicacionComponent } from './Pages/addImplicacion/addImplicacion.component';
import { AjustesComponent } from './Pages/ajustes/ajustes.component';
import { ClienteComponent } from './Pages/cliente/cliente.component';
import { CrmComponent } from './Pages/cliente/crm/crm.component';
import { DailyComponent } from './Pages/daily/daily.component';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { EmpresaComponent } from './Pages/empresa/empresa.component';
import { EquipoComponent } from './Pages/equipo/equipo.component';
import { NuevoIntegranteComponent } from './Pages/equipo/Nuevo-Integrante/Nuevo-Integrante.component';
import { LoginComponent } from './Pages/login/login.component';
import { NuevoPresupuestoComponent } from './Pages/proyectos/nuevo-presupuesto/nuevo-presupuesto.component';
import { NuevoProyectoComponent } from './Pages/proyectos/Nuevo-Proyecto/Nuevo-Proyecto.component';
import { PresupuestosComponent } from './Pages/proyectos/presupuestos/presupuestos.component';
import { ProyectosComponent } from './Pages/proyectos/proyectos.component';
import { CosteMesComponent } from './Pages/tesoreria/costes/coste-mes/coste-mes.component';
import { CostesComponent } from './Pages/tesoreria/costes/costes.component';
import { HitosComponent } from './Pages/tesoreria/hitos/hitos.component';
import { TesoreriaComponent } from './Pages/tesoreria/tesoreria.component';
import { VacacionesComponent } from './Pages/vacaciones/vacaciones.component';
import { WeeklyComponent } from './Pages/weekly/weekly.component';
import { PagoComponent } from './Components/pago/pago.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'implicacion',
    component: WeeklyComponent,
  },
  {
    path: 'daily',
    component: DailyComponent,
  },
  {
    path: 'vacaciones',
    component: VacacionesComponent,
  },
  {
    path: 'clientes',
    component: ClienteComponent,
  },
  {
    path: 'crm',
    component: CrmComponent,
  },
  {
    path: 'proyectos',
    component: ProyectosComponent,
  },
  {
    path: 'proyectos/nuevo-proyecto',
    component: NuevoProyectoComponent,
  },
  {
    path: 'presupuestos',
    component: PresupuestosComponent,
  },
  {
    path: 'presupuestos/nuevo-presupuesto',
    component: NuevoPresupuestoComponent,
  },
  {
    path: 'presupuestos/cerrar/:id',
    component: NuevoPresupuestoComponent,
  },
  {
    path: 'proyectos/editar-proyecto/:id',
    component: NuevoProyectoComponent,
  },
  {
    path: 'proyectos/:id',
    component: PopupInformacionProyectoComponent,
  },
  {
    path: 'equipo',
    component: EquipoComponent,
  },
  {
    path: 'equipo/nuevo-integrante',
    component: NuevoIntegranteComponent,
  },
  {
    path: 'tesoreria',
    component: TesoreriaComponent,
  },
  {
    path: 'costes',
    component: CostesComponent,
  },
  {
    path: 'costes/:year/:month',
    component: CosteMesComponent,
  },
  {
    path: 'hitos',
    component: HitosComponent,
  },
  {
    path: 'ajustes',
    component: AjustesComponent,
  },
  {
    path: 'empresa',
    component: EmpresaComponent,
  },
  {
    path: 'pay',
    component: PagoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
