<div class="dashboardGeneralContainer">
    <div class="sideBarGeneralContainer">
      <sidebar [elementoActivo]="'daily'"></sidebar>
    </div>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar titulo="Daily" (accionBoton)="addDaily()" [boton]="true"></app-header-bar>
      </div>
  
      <div class="dataBodyContainer"> 
        <div class="cabecera">
          <div class="selectorFecha">
            <div (click)="decrementarUnDia()" class="flecha izq"></div>
            <span class="fecha">{{fecha | date:'d'}} de {{fecha|date:'MMMM'}}</span>
            <div (click)="incrementarUnDia()" class="flecha der"></div>
            <input type="date" (change)="cambioFecha()" [(ngModel)]="fecha" class="selectorFecha">
          </div>
          <div class="selectorEmpleado" *ngIf="admin">
            <ng-select [items]="trabajadores" bindLabel="fullName" bindValue="id" [(ngModel)]="idUser" (change)="buscarDaily()"></ng-select>
          </div>
          <div class="semana">
            <div>Semana {{fecha|date:'w'}}</div>
            <div class="dias">
              <div *ngFor="let dia of diasSemana" (click)="seleccionarDia(dia)" [class.seleccionado]="mostrarFechaString(dia)==fecha"><span>{{dia|date:'EEEEE': 'es-ES'}}</span><span>{{dia|date:'d'}}</span></div>
            </div>
          </div>
        </div>
        <div>
        <div class="tabla">
            <div class="fila cabeceraTabla">
              <div class="columna trabajar"></div>
              <div class="columna">Proyecto</div>
              <div *ngIf="admin" class="columna">Empleado</div>
              <div class="columna">Proceso</div>
              <div class="columna">Subproceso</div>
              <div class="columna horasEstimadas">Horas estimadas</div>
              <div class="columna validacion">Validación</div>
              <div class="columna keyResult">Key result</div>
              <div class="columna trabajar"></div>
            </div>
            <daily-component *ngFor="let item of daily" [idActivo]="idActivo" [daily]="item" (cambioEnActivo)="obtenerClockifyActivo()" (refrescarDailys)="buscarDaily()" (click)="editarDaily(item)"></daily-component>
        <div class="selector" *ngIf="false">
          <div class="fecha">
            <span class="dia">{{fecha|date:'d'}}</span>
            <span class="mes">{{fecha|date:'MMMM'}}</span>
            <span class="anyo">{{fecha|date:'yyyy'}}</span>
          </div>
          <div class="calendario">
            <full-calendar [events]="eventos|async" [options]="calendarOptions"></full-calendar>
          </div>
          <div class="empleados" *ngIf="admin">
            <div *ngFor="let trabajador of trabajadores" [class.seleccionado]="trabajador.id == idUser" (click)="cambioUsuario(trabajador.id)">{{trabajador.nombre}} {{trabajador.apellidos}}</div>
          </div>

        </div>
        <div class="dailys" *ngIf="false">
          <daily-component *ngFor="let item of daily" [idActivo]="idActivo" [daily]="item" (cambioEnActivo)="obtenerClockifyActivo()" (refrescarDailys)="buscarDaily()" (click)="editarDaily(item)"></daily-component>
          <mat-paginator *ngIf="cantidadPaginas > pageSize" [length]="cantidadPaginas"
            [pageIndex]="pagina"
            (page)="handlePageEvent($event)"
            [pageSize]=pageSize
            [pageSizeOptions]="[]"
            aria-label="Select page">
          </mat-paginator>
        </div>
    </div>
  </div>
  