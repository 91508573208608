import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CosteEmpresaService } from 'src/app/Services/coste-empresa.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { PopUpCosteComponent } from 'src/app/Components/pop-up-coste/pop-up-coste.component';
import { PopupService } from 'src/app/Services/popup.service';


@Component({
  selector: 'coste-mes',
  templateUrl: './vista-costes-mes.component.html',
  styleUrls: ['./vista-costes-mes.component.css']
})

export class VistaCostesMesComponent extends UnsubscribesDestroy implements OnInit {

  @Input() year;
  @Input() month;

  
  ngOnChanges(changes) {
    if(changes.tarea != undefined){
  }
  if(changes.month != undefined || changes.year != undefined){
    this.cargarInformacion();
  }
}

costesFijos = [];
costesVariables = [];
costesPeriodicos = [];
totalFijo = 0;
totalVariable = 0;
totalPeriodico = 0;

valoresGrafico = [0,0,0,0,0,0,0,0];
coloresGrafico = ["#fc464e","#ffa5c8","#dca8ab","#ffd6e0","#f9b3c3","#c996b6","#99738e","#6c5b7b"];

  constructor(
    protected loaderSE: LoaderService,
    protected popup: PopupService,
    protected costeSE: CosteEmpresaService,
    protected activatedRoute : ActivatedRoute) {
      super();
    }
    cargando = false;
cargarInformacion(){
  if(!this.cargando){
    this.cargando = true;
    this.loaderSE.open();
    this.costeSE.getCostesYearMonth(this.year,this.month);
    this.costeSE.getResult()
    .pipe(takeUntil(this._unsubscribeIndividual2))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      this.res = value;
      this.valoresGrafico = [0,0,0];
      this.costesFijos = [];
      this.costesVariables = [];
      this.costesPeriodicos = [];
      this.totalFijo = 0;
      this.totalVariable = 0;
      this.totalPeriodico = 0;
      this.res.forEach(coste => {
        this.valoresGrafico[coste.tipo] += coste.cantidad;
        switch(coste.tipo){
          case 0:
            this.costesFijos.push(coste);
            this.totalFijo += coste.cantidad;
            break;
          case 1:
            this.costesVariables.push(coste);
            this.totalVariable += coste.cantidad;
            break;
          case 2:
            this.costesPeriodicos.push(coste);
            this.totalPeriodico += coste.cantidad;
            break;
        }
      });
      this.ordenarPorFamilias();
      this.loaderSE.closeContador();
      this._unsubscribeIndividual2.next('');
      this.cargando = false;
    });
  }
}

ordenarPorFamilias(){
  this.costesFijos = this.ordenarPorFamilia(this.costesFijos);
  this.costesVariables = this.ordenarPorFamilia(this.costesVariables);
  this.costesPeriodicos = this.ordenarPorFamilia(this.costesPeriodicos);
}
ordenarPorFamilia(familia){
  if(familia.length == 0){
    return [];
  }
 let ordenado = [];
 let costeAnterior = this.guardarCostesPorValor(familia[0]);
 costeAnterior.cantidad = 0;
let interiores = [];
 familia.forEach(coste => {
  if(coste.familia == null){
    if(interiores.length > 1){
    costeAnterior.interior = interiores;
    }else{
      costeAnterior.cantidad = interiores[0].cantidad;
      costeAnterior.interior = [];
    }
    ordenado.push(costeAnterior);
    interiores =[];
    costeAnterior = this.guardarCostesPorValor(coste);
    costeAnterior.cantidad = 0;
    costeAnterior.cantidad -= coste.cantidad;
  }else{
    if(coste.familia.nombre != costeAnterior.familia.nombre){
      if(interiores.length > 1){
      costeAnterior.interior = interiores;
      }else{
        costeAnterior.cantidad = interiores[0].cantidad;
        costeAnterior.interior = [];
      }
      ordenado.push(costeAnterior);
      interiores =[];
      costeAnterior = this.guardarCostesPorValor(coste);
      costeAnterior.cantidad = 0;
    }
  }
    costeAnterior.cantidad += coste.cantidad;
    interiores.push(this.guardarCostesPorValor(coste));
 });
 if(interiores.length > 1){
 costeAnterior.interior = interiores;
 }else{
   costeAnterior.cantidad = interiores[0].cantidad;
   costeAnterior.interior = [];
 }
 ordenado.push(costeAnterior);
 return ordenado;
}

guardarCostesPorValor(coste){
  let data = {id:coste.id,cantidad:coste.cantidad,concepto:coste.concepto,iva:coste.iva,proyecto:coste.proyecto,tipo:coste.tipo,familia:coste.familia,interior:[],abierto:false};
  return data;
}
abrirCoste(coste){
  if(coste.interior.length > 0){
    coste.abierto = !coste.abierto;
  }
}
  ngOnInit(): void {
    this.cargarInformacion();
  }
res;
  meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

  abrirPopUp(tipo){
    
    let data = {tipo:tipo,year:this.year,month:this.month};
    this.popup.openPopup(PopUpCosteComponent,data);    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue) {
        this.popup.clearData();
        this.cargarInformacion();        
        this._unsubscribeIndividual.next('');
      }
    });

    
  }
}