import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, HostListener, ViewChild} from '@angular/core';
import { ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexYAxis,
  ApexForecastDataPoints,
  ApexPlotOptions,
  ApexGrid} from 'ng-apexcharts';
import { Subject, takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';
import { PopupService } from 'src/app/Services/popup.service';
import { ProyectosService } from 'src/app/Services/proyectos.service';
import { PopupConfirmacionBorradorComponent } from '../popup-confirmacion-borrador/popup-confirmacion-borrador.component';
import { PopupGeneralProcesosComponent } from '../popup-general-procesos/popup-general-procesos.component';
import { PopupInformacionEmpleadoComponent } from '../popup-informacion-empleado/popup-informacion-empleado.component';
import { PopUpCosteComponent } from '../pop-up-coste/pop-up-coste.component';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  forecastDataPoints: ApexForecastDataPoints;
};
@Component({
  selector: 'popup-informacion-proyecto',
  templateUrl: './popup-informacion-proyecto.component.html',
  styleUrls: ['./popup-informacion-proyecto.component.css'],
})
export class PopupInformacionProyectoComponent implements OnInit, OnDestroy {

  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calcularAnchosMeses();
 }


  _unsubscribe = new Subject<any>();
  _unsubscribeIndividual = new Subject<any>();
  _unsubscribeIndividual2 = new Subject<any>();
  _unsubscribeIndividual3 = new Subject<any>();
  ngOnDestroy(): void {
    this._unsubscribe.next(' ');
    this._unsubscribe.complete();
    this._unsubscribeIndividual.next(' ');
    this._unsubscribeIndividual.complete();
    this._unsubscribeIndividual2.next(' ');
    this._unsubscribeIndividual2.complete();
    this._unsubscribeIndividual3.next(' ');
    this._unsubscribeIndividual3.complete();
  }
  opcionSubmenu: string = 'Corto Plazo';
  opcion: string = 'Todas';
  opcionMenu: string = 'Dashboard';
  tipo: string = '';
  enviarProceso: any;
  @Output() estado = new EventEmitter<boolean>();
  proyectoEntrada: any;
  proyecto: any = {
    etiqueta: null,
    fecha_fin: null,
  };
  @Input() tipoPopup: any;
  @ViewChild('chartMargen') chartMargen!: ChartComponent;
  @ViewChild('chartPresupuesto') chartPresupuesto!: ChartComponent;
  @ViewChild('chartAvance') chartAvance!: ChartComponent;
  @ViewChild('chart') chart!: ChartComponent;
  filtroFases = ['Todas'];
  menuGeneral = ['Dashboard', 'Costes', 'Anterior', 'Equipo'];
  iconos = ['/assets/icons/info.svg', '/assets/icons/coste.svg', '/assets/icons/info.svg', '/assets/icons/equipo_proyecto.svg'];

  procesos = [];
  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    protected proyectoServie: ProyectosService,
    public popup: PopupService,
    private loaderSE: LoaderService,
    protected http: HttpClient,
    protected activatedRoute: ActivatedRoute
  ) {

  }
  fases = [];
  ngOnInit(): void {
    this.proyectoEntrada = this.activatedRoute.snapshot.params['id'];
    this.cargaInicial();
    this.calcularAnchosMeses();
  }

  generarDiasGraficas(){
    this.hoy = new Date(this.proyecto.fecha_ini);
    this.lunes[0] = this.getMonday(this.hoy);
    this.domingo = this.endWeek(this.lunes[0]);
    let fin = false;
    let i = 1;
    while (!fin) {
      this.lunes[i] = this.addWeek(this.lunes[i - 1]);
      if(new Date(this.proyecto.fecha_fin).getTime() <= this.lunes[i].getTime()){
        fin = true;
      }
      i++;
    }    
  }
  colores: string[] = [
    '#F9D8DA',
    '#B0EEEF',
    '#B7EEBE',
    '#FFF7C2',
    '#F8D8E7',
    '#E6DAFC',
    '#D3E1FC',
    '#C3E5FC',
    '#E0E0E0',
    '#DEE1E5',
    '#EFF1D6',
  ];
  estadisticas;
  dailyCategoria = true;
  valoresDaily = [];
  coloresDaily = [];
  infoDaily = [];
  graficoDaily = '';
  graficaDailys(opcion){
    this.graficoDaily = opcion;
    let cont = 0;
    switch (opcion) {
      case 'Categoria':
        this.valoresDaily = [];
        this.coloresDaily = [];
        this.infoDaily = [];
        this.categoraiasDaily.forEach(categoria => {
          this.infoDaily.push({color:categoria.color,
            nombre:categoria.categoria,
            cant:categoria.cant});
          this.valoresDaily.push(categoria.cant);
          this.coloresDaily.push(categoria.color);
        });
        break;
      case 'Usuario':
        this.valoresDaily = [];
        this.coloresDaily = [];
        this.infoDaily = [];
        this.usuariosDaily.forEach(usuario => {
          this.infoDaily.push({color:this.colores[cont],
            nombre:usuario.nombre + ' ' + usuario.apellidos,
            cant:usuario.cant});
          this.valoresDaily.push(usuario.cant);
          this.coloresDaily.push(this.colores[cont]);
          cont++;
        });
        break;
        case 'Proceso':
          this.valoresDaily = [];
          this.coloresDaily = [];
          this.infoDaily = [];
          this.procesosDaily.forEach(proceso => {
            this.infoDaily.push({color:this.colores[cont],
              nombre:proceso.nombre,
              cant:proceso.cant});
            this.valoresDaily.push(proceso.cant);
            this.coloresDaily.push(this.colores[cont]);
            cont++;
          });
          break;
      default:  

        break;
    }
  }
  graficaMargen(){
    this.margenGrafica.series[0].data = [];
    this.margenGrafica.annotations.yaxis[0].y = this.proyecto.multiplicador - 1;
    
    this.proyecto.historico_margenes.forEach(margen => {
      this.margenGrafica.series[0].data.push([new Date(margen.fecha).getTime(),margen.margen]);
    }); 
    console.log(this.proyecto.fecha_ini);
    this.margenGrafica.xaxis.min = new Date(this.proyecto.fecha_ini).getTime();
    this.margenGrafica.xaxis.max = new Date(this.proyecto.fecha_fin).getTime();
    this.margenGrafica.yaxis.min = 0;
    if(this.chartMargen != undefined){
      this.chartMargen.updateSeries(this.margenGrafica.series);
      this.chartPresupuesto.updateSeries(this.presupuestoGrafica.series);
    }
  }


  
  graficaAvance(){
    this.avanceGrafica.series[0].data = [];
    this.avanceGrafica.series[1].data = [];
    this.avanceGrafica.forecastDataPoints.count = 1;    
    
   if(this.proyecto.historico_avances.length > 0){
    let inicio = new Date(this.proyecto.historico_avances[0].fecha).getTime();
    if(inicio != this.lunes[0].getTime()){
      this.avanceGrafica.series[1].data.push([this.lunes[0].getTime(),0]);
    }
   }else{
    this.avanceGrafica.series[1].data.push([this.lunes[0].getTime(),0]);
   }    
    this.proyecto.historico_avances.forEach(avance => {
      this.avanceGrafica.series[1].data.push([new Date(avance.fecha).getTime(),avance.avance]);
    });

    this.avanceGrafica.series[1].data.push([this.lunes[this.lunes.length-1].getTime(),1]);
    this.avanceGrafica.series[0].data = this.generarLineaProgresoDias(this.avanceGrafica.series[1].data);    
    if(this.chartMargen != undefined){
      this.chartMargen.updateSeries(this.avanceGrafica.series);
      this.chartPresupuesto.updateSeries(this.presupuestoGrafica.series);
    }
  }

  generarLineaProgresoDias(dias){
    let diasComparar = [];
    dias.forEach(dia => {
      diasComparar.push(dia[0]);
    });
    let linea = [];
    let paso = 1/(this.lunes.length-1);
    let avance = 0;
    this.lunes.forEach(element => {
      //si el lunes se encuentra en el array de dias lo añadimos a la linea
      if(diasComparar.includes(element.getTime())){
        linea.push([element.getTime(),avance]);
      }
      avance += paso;
    });   
    return linea;
  }

  calcularPasoPrevision(objetivo,actual,pasos){
    let paso = (objetivo - actual)/pasos;
    return paso;
  }

  hoy;
  lunes = [];
  domingo;

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  endWeek(day) {
    var date = new Date(day.valueOf());
    date.setDate(date.getDate() + 6);
    return date;
  }
  addWeek(day) {
    var date = new Date(day.valueOf());
    date.setDate(date.getDate() + 7);
    return date;
  }

categoraiasDaily = [];
usuariosDaily = [];
procesosDaily = [];
fasesGantt = [];
  cargaInicial(){
    this.procesos = [];
    this.fases = [];
    this.filtroFases = ['Todas'];
    this.proyectoServie.getProyecto(this.proyectoEntrada);
    this.loaderSE.open();
    this.proyectoServie
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.proyecto = value.proyecto;
        this.estadisticas = value.estadisticas;
        this.categoraiasDaily = value.dailys;
        this.usuariosDaily = value.usuarios;
        this.procesosDaily = value.procesos;
        this.graficaDailys('Categoria');
        this.generarDiasGraficas();
        this.graficaMargen();
        this.graficaAvance();
        this.proyecto.horasTrabajadas = this.estadisticas.horasClockify;

        this.proyecto.fases.forEach((element) => {
          this.fases.push(element);
          this.filtroFases.push(element.nombre);
          let tareas = [];
          element.procesos.forEach((elementProceso) => {
            elementProceso['fase'] = { nombre: element.nombre, id: element.id };
            elementProceso['fases'] = this.proyecto.fases;
            elementProceso['proyectoID'] = this.proyecto.id;
            tareas.push({
              id: elementProceso.id,
              nombre: elementProceso.nombre,
              start: new Date(elementProceso.fecha_ini).getTime(),
              end: new Date(elementProceso.fecha_fin).getTime(),
              completado: elementProceso.completado,
            });
              
            this.procesos.push(elementProceso);
          });
          
          this.fasesGantt.push({ id: element.id, name: element.nombre, tareas:tareas });
        });
        this.costes = value.costes;

        this.costes.Fijo.forEach((element) => {
          this.costeDirecto += element.cantidad;
        });
        this.costes.Variable.forEach((element) => {
          this.costeIndirecto += element.cantidad;
        });
        this.costes.Periodo.forEach((element) => {
          this.costePeriodo += element.cantidad;
        });
        this.costeTotal = this.costeDirecto + this.costeIndirecto + this.costePeriodo + this.estadisticas.costeReal;

        this._unsubscribeIndividual.next('');
        this.proyectoServie.clearResult();
        this.loaderSE.closeContador();
      });
  }

  diasEntreFechas(fecha1, fecha2) {
    let diferencia = fecha2.getTime() - fecha1.getTime();
    let dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    return dias;
  }
  costeTotal = 0;
  costeDirecto = 0;
  costeIndirecto = 0;
  costePeriodo = 0;


  abrirInformacionEmpleado(trabajador: any) {
    this.popup.openPopup(PopupInformacionEmpleadoComponent, trabajador);
  }
  cerrarInfoProyecto() {
    this.estado.emit(false);
  }
  opcionSubMenuSeleccionada(event: string) {
    this.opcionSubmenu = event;
  }
  opcionSeleccionada(event: string) {
    this.opcion = event;
  }

  opcionSeleccionadaMenu(event: string) {
    this.opcionMenu = event;
    
    if (this.chartMargen != undefined) {
      this.chartMargen.updateSeries(this.margenGrafica.series);
      this.chartPresupuesto.updateSeries(this.presupuestoGrafica.series);
    }
  }

  openAddProceso(tipo: string, proceso: any, posicion:number) {
    this.popup.openPopup(PopupGeneralProcesosComponent, {
      tipo: tipo,
      proceso: proceso,
      fase: this.fases,
      numeroProceso: posicion
    });
    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue) {
        this.cargaInicial();
      }
    });
  }
  colorFase(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    r /= 255;
    g /= 255;
    b /= 255;
    var max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    var h,
      s,
      l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    l = l - 10;
    h = Math.round(360 * h);

    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }
  cambioEstado(nuevoEstado){
    switch(nuevoEstado){
      case -1:
        this.popup.openPopup(PopupConfirmacionBorradorComponent, {
          descripcion: '¿Desea archivar el proyecto?',
        });
        this.popup
          .returnData()
          .pipe(takeUntil(this._unsubscribeIndividual))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            if (value.returnValue) {
              this.proyectoServie.editProyecto(this.proyecto.id, {archivar: true});
  
              this.loaderSE.open();
              this.proyectoServie
                .getResultUpdate()
                .pipe(takeUntil(this._unsubscribeIndividual2))
                .subscribe((value) => {
                  if (value == null) {
                    return;
                  }
                  this.proyectoServie.clearResultUpdate();
                  this.proyecto.potencial = true;
                  this.loaderSE.closeContador();
                });
            }
          });
        break;
        case 1:
          this.popup.openPopup(PopupConfirmacionBorradorComponent, {
            descripcion: '¿Desea reanudar el proyecto?',
          });
          this.popup
            .returnData()
            .pipe(takeUntil(this._unsubscribeIndividual))
            .subscribe((value) => {
              if (value == null) {
                return;
              }
              if (value.returnValue) {
                this.proyectoServie.editProyecto(this.proyecto.id, {finalizar: false});
    
                this.loaderSE.open();
                this.proyectoServie
                  .getResultUpdate()
                  .pipe(takeUntil(this._unsubscribeIndividual2))
                  .subscribe((value) => {
                    if (value == null) {
                      return;
                    }
                    this.proyectoServie.clearResultUpdate();
                    this.proyecto.potencial = false;
                    this.loaderSE.closeContador();
                  });
              }
            });
          break;
      case 2:
        this.popup.openPopup(PopupConfirmacionBorradorComponent, {
          descripcion: '¿Desea finalizar el proyecto?',
        });
        this.popup
          .returnData()
          .pipe(takeUntil(this._unsubscribeIndividual))
          .subscribe((value) => {
            if (value == null) {
              return;
            }
            if (value.returnValue) {
              this.proyectoServie.editProyecto(this.proyecto.id, {finalizar: true});
  
              this.loaderSE.open();
              this.proyectoServie
                .getResultUpdate()
                .pipe(takeUntil(this._unsubscribeIndividual2))
                .subscribe((value) => {
                  if (value == null) {
                    return;
                  }
                  this.proyectoServie.clearResultUpdate();
                  this.proyecto.potencial = false;
                  this.loaderSE.closeContador();
                });
            }
          });
        break;
      case 0://pausar
      this.popup.openPopup(PopupConfirmacionBorradorComponent, {
        descripcion: '¿Desea pausar el proyecto?',
      });
      this.popup
        .returnData()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          if (value.returnValue) {
            this.proyectoServie.editProyecto(this.proyecto.id, {pausar: true});

            this.loaderSE.open();
            this.proyectoServie
              .getResultUpdate()
              .pipe(takeUntil(this._unsubscribeIndividual2))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.proyectoServie.clearResultUpdate();
                this.proyecto.potencial = false;
                this.loaderSE.closeContador();
              });
          }
        });
        break;
    }
  }

  activarProyecto() {
    if (this.proyecto.potencial) {
      this.popup.openPopup(PopupConfirmacionBorradorComponent, {
        descripcion: '¿Pasar el proyecto a activo?',
      });
      this.popup
        .returnData()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          if (value.returnValue) {
            this.proyectoServie.editProyecto(this.proyecto.id, {soloPotencial: false});

            this.loaderSE.open();
            this.proyectoServie
              .getResultUpdate()
              .pipe(takeUntil(this._unsubscribeIndividual2))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.proyectoServie.clearResultUpdate();
                this.proyecto.potencial = false;
                this.loaderSE.closeContador();
                this._unsubscribeIndividual2.next('');
              });

              
            this.proyectoServie
            .getResultUpdateError()
            .pipe(takeUntil(this._unsubscribeIndividual3))
            .subscribe((value) => {
              if (value == null) {
                return;
              }
              this.proyectoServie.clearResultUpdate();
              this.loaderSE.closeContador();
              this._unsubscribeIndividual3.next('');
            });


          }
          this.popup.clearData();
          this._unsubscribeIndividual.next('');
        });
    } else {
      this.popup.openPopup(PopupConfirmacionBorradorComponent, {
        descripcion: '¿Pasar el proyecto a inactivo?',
      });
      this.popup
        .returnData()
        .pipe(takeUntil(this._unsubscribeIndividual))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          if (value.returnValue) {
            this.proyectoServie.editProyecto(this.proyecto.id, {soloPotencial: true});

            this.loaderSE.open();
            this.proyectoServie
              .getResultUpdate()
              .pipe(takeUntil(this._unsubscribeIndividual2))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.proyectoServie.clearResultUpdate();
                this.proyecto.potencial = true;
                this.loaderSE.closeContador();
                this._unsubscribeIndividual2.next('');
              });

              
            this.proyectoServie
            .getResultUpdateError()
            .pipe(takeUntil(this._unsubscribeIndividual3))
            .subscribe((value) => {
              if (value == null) {
                return;
              }
              this.proyectoServie.clearResultUpdate();
              this.loaderSE.closeContador();
              this._unsubscribeIndividual3.next('');
            });

          }
          this.popup.clearData();
          this._unsubscribeIndividual.next('');
        });
    }
  }


  margenGrafica: any = {
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: '#00E396',
          strokeDashArray: 0,
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              fontFamily: 'Satoshi', 
              background: '#00E396',
            },
            text: 'Objetivo',
          }
        },
        
        {
          y: 0.3,
          borderColor: '#fd454d',
          strokeDashArray: 0,
          label: {
            borderColor: '#fd454d',
            style: {
              color: '#fff',
              fontFamily: 'Satoshi', 
              background: '#fd454d',
            },
            text: 'Suelo',
          }
        }
      ]
    },
    series: [
      {
        name: "Real",
        data: []
      }
    ],
    chart: {
      height: 240,
      toolbar:{
        show:false
      },
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      curve: "smooth"
    },
    grid: {
    },
    xaxis: {
      type: "datetime",
      labels: {
        style:{
          colors:'#192b45',
          fontFamily: 'Satoshi',          
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return  Math.round(value*100) + '%';
        },
        style:{
          colors:'#1f586b',
          fontFamily: 'Satoshi',          
        },
      },
      forceNiceScale: true,
    },
    tooltip: {
      style:{
        colors:'#1f586b',
        fontFamily: 'Satoshi',          
      },
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        let val = series[seriesIndex][dataPointIndex]*100;
        let round = Math.round(val * 100) / 100;
        return '<div class="arrow_box_margen">' +
          '<span> Margen - ' + round + ' %</span>' +
          '</div>'
      }
    }
  }

  presupuestoGrafica: any = {
    annotations: {
      yaxis: []
    },
    series: [
      {
        name: "Real",
        data: [[new Date('2023-03-26').getTime(),0],[new Date('2023-04-08').getTime(),30000],[new Date('2023-04-18').getTime(),45000],[new Date('2023-05-05').getTime(),94062]]
      },
      {
        name: "Presupuestado",
        data: [[new Date('2023-03-26').getTime(),0],[new Date('2023-04-08').getTime(),10000],[new Date('2023-05-05').getTime(),111999.94],[new Date('2023-05-15').getTime(),120000],[new Date('2023-06-25').getTime(),639200],[new Date('2023-07-02').getTime(),659200]]
      }
    ],
    chart: {
      height: 240,
      toolbar:{
        show:false
      },
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      curve: "straight"
    },
    grid: {
    },
    xaxis: {
      type: "datetime",
      labels: {
        style:{
          colors:'#192b45',
          fontFamily: 'Satoshi',          
        },
      },
    },
    yaxis: {
      max: 670000,
      labels: {
        formatter: function (val: any) {            
          let formatter = new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
          });
          return formatter.format(val);
        },
        style:{
          colors:'#1f586b',
          fontFamily: 'Satoshi',          
        },
      },
      tickAmount: 5,
    },
    tooltip: {
      style:{
        colors:'#1f586b',
        fontFamily: 'Satoshi',          
      }
    }
  }

  avanceGrafica: any = {
    forecastDataPoints:{
      count: 8,
      dashArray: 4
    },
    annotations: {
      xaxis: [
        {
          x: new Date().getTime(),
          borderColor: '#ccc',
          width: 5,
          strokeDashArray: 0          
        }
      ]
    },
    series: [
      {
        name: "Objetivo",
        data: []
      },{
        name: "Real",
        data: []
      }
    ],
    chart: {
      height: 240,
      toolbar:{
        show:false
      },
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      curve: ["straight","straight"]
    },
    grid: {
    },
    xaxis: {
      type: "datetime",
      labels: {
        style:{
          colors:'#192b45',
          fontFamily: 'Satoshi',          
        },
      },
    },
    yaxis: {
      min: 0,
      max: 1,
      tickAmount: 5,
      labels: {
        formatter: (value: any) => {
          return  Math.round(value*100) + '%';
        },
        style:{
          colors:'#1f586b',
          fontFamily: 'Satoshi',          
        },
      }
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        let dia = w.config.series[seriesIndex].data[dataPointIndex][0];
        let arrayBusqeuda = 0;
        if(seriesIndex == 0){
          arrayBusqeuda = 1;
        }
        let valor = -1;
        w.config.series[arrayBusqeuda].data.forEach(element => {
          if(element[0] == dia){
            valor = element[1];
          }
        });
        if(valor == -1){
          return '';
        }
        let objetivo;
        let actual;
        if(seriesIndex == 0){
          objetivo = series[0][dataPointIndex];
          actual = valor;
        }else{
          objetivo = valor;
          actual = series[1][dataPointIndex];
        }
          let round = Math.round((actual - objetivo) * 100) / 100;
          return '<div class="arrow_box_margen">' +
          '<span> Diferencia: ' + round + ' %</span>' +

            '</div>';
      }
    }
  }


  
  tamanyosMeses = ['3M', '6M', '12M'];
  ancho3Meses = 0;
  ancho6Meses = 0;
  ancho12Meses = 0;
  anchoDia = 20;
  cambioAnchoDia(val){
    if(this.anchoDia + val > 2){
    this.anchoDia += val;
    }
  }
  cambioTamDias(tam){
    switch(tam){
      case '12M':
        this.anchoDia = this.ancho12Meses;
        break;
      case '6M':
        this.anchoDia = this.ancho6Meses;
        break;
      case '3M':
        this.anchoDia = this.ancho3Meses;
        break;
    }
  }
  
  calcularAnchosMeses(){
    let ancho = window.innerWidth;
    let anchoGant = ancho- 72 - 64 - 64 - 100 - 25;
    this.ancho3Meses = anchoGant / 90;
    this.ancho6Meses = anchoGant / 180;
    this.ancho12Meses = anchoGant / 365;
  }

costes = null;
  
  abrirPopUp(event){
    event.stopPropagation();
    let data = {proyecto:this.proyecto.id};
    this.popup.openPopup(PopUpCosteComponent,data);    
    this.popup
    .returnData()
    .pipe(takeUntil(this._unsubscribeIndividual))
    .subscribe((value) => {
      if (value == null) {
        return;
      }
      if (value.returnValue) {
        this.popup.clearData();
        this.cargaInicial();
        this._unsubscribeIndividual.next('');
      }
    });

    
  }

  acordeon = [false,false,false];

}
