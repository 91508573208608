<div class="sideBarContainer" [class.abierto]="abierto">
  <div class="sidebarLogoContainer">
    <img src="../../../assets/Images/fenech_logo.png" alt="" />
  </div>
  <div class="sidebarEnlacesContainer">
    <ul>
      <li *ngIf="admin" [class.activo]="elementoActivo == 'dashboard'">
        <a routerLink="/dashboard">

          <img src="/assets/icons/dashboard.svg">
          <span>Dashboard</span>
        </a>
      </li>
      <li *ngIf="admin" [class.activo]="elementoActivo == 'proyectos' ||elementoActivo == 'cotizador' || elementoActivo == 'presupuestos'">
        <a routerLink="/proyectos">
          <img src="/assets/icons/proyectos.svg">
          <span>Proyectos</span>
        </a>
        <ul>
          <li>
            <a routerLink="/proyectos" [class.activo]="elementoActivo == 'proyectos'">Proyectos</a>
          </li>
          <li>
            <a routerLink="/presupuestos/nuevo-presupuesto" [class.activo]="elementoActivo == 'cotizador'">Cotizador</a>
          </li>
          <li *ngIf="false">
            <a routerLink="/presupuestos" [class.activo]="elementoActivo == 'presupuestos'">Presupuestos</a>
          </li>
        </ul>
      </li>
      <li *ngIf="admin" [class.activo]="elementoActivo == 'equipo' || elementoActivo == 'vacaciones'">
        <a routerLink="/equipo">
          <img src="/assets/icons/equipo.svg">
          <span>Equipo</span>
        </a>
        <ul *ngIf="false">
          <li [class.activo]="elementoActivo == 'equipo'">
            <a routerLink="/equipo">
              <span>Resumen</span>
              </a>
            </li>
          <li [class.activo]="elementoActivo == 'vacaciones'">
            <a routerLink="/vacaciones">
              <span>Vacaciones</span>
              </a>
            </li>
        </ul>
      </li>
      <li *ngIf="admin" [class.activo]="elementoActivo == 'tesoreria' || elementoActivo == 'hitos' || elementoActivo == 'costes'">
        <a routerLink="/hitos">
          <img src="/assets/icons/tesoreria.svg">
          <span>Tesorería</span>
        </a>
        <ul>
          <li>
            <a routerLink="/hitos" [class.activo]="elementoActivo == 'hitos'">Hitos de pago</a>
          </li>
          <li>
            <a routerLink="/tesoreria" [class.activo]="elementoActivo == 'tesoreria'">Facturas</a>
          </li>
          <li>
            <a routerLink="/costes" [class.activo]="elementoActivo == 'costes'">Costes</a>
          </li>
        </ul>
      </li>
      <li *ngIf="admin" [class.activo]="elementoActivo == 'crm' || elementoActivo == 'cliente'">
        <a routerLink="/crm">
          <img src="/assets/icons/crm.svg">
          <span>CRM</span>
          </a>
        <ul>
          <li>
            <a routerLink="/clientes" [class.activo]="elementoActivo == 'cliente'">Clientes</a>
          </li>
          <li>
            <a routerLink="/crm" [class.activo]="elementoActivo == 'crm'">Funnel</a>
          </li>
        </ul>
      </li>
      <li [class.activo]="elementoActivo == 'implicacion' || elementoActivo == 'daily' ">
        <a routerLink="/daily">
          <img src="/assets/icons/implicacion.svg">
          <span>Implicación</span>
        </a>
        <ul>
          <li [class.activo]="elementoActivo == 'implicacion'">
            <a routerLink="/implicacion">Weekly</a>
          </li>
          <li [class.activo]="elementoActivo == 'daily'">
            <a routerLink="/daily">Daily</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div (click)="abierto = !abierto" class="cambiarEstado"><span class="flecha"></span></div>
</div>
