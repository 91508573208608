<div class="confirmarGuardarBorradorGeneralContainer">
    <div class="confirmarGuardarBorradorConainer">
      <div class="bodyGuardarBorrador">
        <span>Añadir un nuevo coste</span>
        <div class="formulario">
            <div class="linea">
                <div class="campo tam_1">
                    <label>Tipo de coste</label>
                    <menu-navegacion [colorSecundario]="true" [condensed]="true" [redondo]="true" [opcionPorDefecto]="tipo" [opciones]="['Variable','Fijo','Periodo']" (emitOpcionSeleccionada)="opcionSeleccionadaTipo($event)"></menu-navegacion>
                </div>
                <div class="campo tam_1">
                    <label>Coste</label>
                    <input type="number" [(ngModel)]="coste">
                </div>
                <div class="campo tam_2">
                    <label>Tipo de IVA</label>
                    <menu-navegacion [colorSecundario]="true" [condensed]="true" [redondo]="true" [opcionPorDefecto]="iva" [opciones]="['21%','10%','4%','Intercomunitario']" (emitOpcionSeleccionada)="opcionSeleccionadaIva($event)"></menu-navegacion>
                </div>
            </div>
            <div class="linea" *ngIf="fechaOpcional">
              <div class="campo tam_1">
                <label>Año</label>
                <input type="number" [(ngModel)]="year">
              </div>
              <div class="campo tam_1">
                <label>Mes</label>
                <ng-select [items]="meses" [multiple]="false" [closeOnSelect]="true" [searchable]="false" [(ngModel)]="month" bindLabel="nombre" bindValue="id"></ng-select>
              </div>
            </div>
            <div class="linea">
                <div class="campo tam_1">
                    <label>Familia</label>
                    <ng-select
                    [items]="familias"
                    placeholder="Selecciona la familia"
                    bindLabel="nombre"
                    bindValue="id"
                    [(ngModel)]="familia"
                  >
                  </ng-select>
                </div>
                <div *ngIf="proyectoOpcional" class="campo tam_1">
                  <label>Proyecto</label>
                  <ng-select
                  [items]="proyectos"
                  placeholder="Selecciona el proyecto"
                  bindLabel="nombre"
                  bindValue="id"
                  [(ngModel)]="proyecto"
                >
                </ng-select>
              </div>
              <div *ngIf="!proyectoOpcional" class="campo tam_1">
                <label>Concepto</label>
                <input [(ngModel)]="concepto">
              </div>
                
                <!--<div class="campo tam_1">
                    <label>Frecuencia del coste</label>
                    <input>
                </div>-->
            </div>
            
            <div *ngIf="proyectoOpcional" class="linea">
              <div class="campo tam_1">
                <label>Concepto</label>
                <input [(ngModel)]="concepto">
              </div>
            </div>
        </div>
        <div class="bodyBotones">
            <button (click)="confirmarGuardarBorrador(false)" type="button">
              Cancelar
            </button>
          <button class="confirmar" (click)="guardarInfo()" type="button">
            Confirmar
          </button>
        </div>
      </div>
    </div>
  </div>
  