import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { PopupInformacionEmpleadoComponent } from 'src/app/Components/popup-informacion-empleado/popup-informacion-empleado.component';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { DepartamentosService } from 'src/app/Services/departamentos.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { LoginService } from 'src/app/Services/login.service';
import { PopupService } from 'src/app/Services/popup.service';
import { UserService } from 'src/app/Services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.css'],
})
export class EquipoComponent extends UnsubscribesDestroy implements OnInit {
  opcion: string = 'Integrantes';
  departamentoActivo = 'Diseño';
  trabajadores = [];
  departamentos = [];
  ngAfterViewChecked(): void {
    this.trabajadores.forEach((element) => {
      this.setPorcentajeTrabajador(
        element.cargaTotal,
        '.' + element.nombre.split(' ')[0] + ''
      );
      if(element.tipo_coste == null){
        element.tipo_coste = '';
      }
    });

    this.departamentos.forEach((element) => {
    });
  }

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public popup: PopupService,
    public router: Router,
    private userSE: UserService,
    private loader: LoaderService,
    private loginSE: LoginService,
    private departamentosSE: DepartamentosService
  ) {
    super();
  }
  ngOnDestroy() {
    this._unsubscribeIndividual.next('');
    this.userSE.clearResult();
  }
  environment;
  ngOnInit(): void {
    this.environment = environment;
    if (
      localStorage.getItem('token') === null &&
      localStorage.getItem('login') != 'login'
    ) {
      this.loginSE.desconectar();
    } else {
      this.getAllUsersByEmpresa();
      setTimeout(() => {
        this.getAllDepartamentosByEmpresa();
      }, 1500);
    }
  }

  abrirInformacionEmpleado(trabajador: any) {
    //this.popup.openPopup(PopupInformacionEmpleadoComponent, trabajador);
  }
  addIntegrante() {
    localStorage.setItem('lastRoute', this.router.url);
    this.router.navigate(['/equipo/nuevo-integrante']);
  }

  opcionSeleccionada(event: string) {
    this.opcion = event;
  }
  setPorcentajeTrabajador(cant: number, elemento: string) {
    var pixel = 472 - (472 / 100) * cant;
    this._document
      .querySelector(elemento)
      ?.setAttribute('stroke-dashoffset', pixel + 'px');
  }
  cambiarActivo(departamento: string) {
    this.departamentoActivo = departamento;
  }

  tipoContratoHoras(horas: number) {
    if(horas == 40){
      return 'Jorna completa';
    }
    if(horas == 20){
      return 'Media jornada';
    }
    return 'Jorna parcial (' + horas + ' h)';
  }

  getAllUsersByEmpresa() {
    this.userSE.getAllUsersByEmpresa();
    this.loader.open();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.loader.closeContador();
        value.forEach((element) => {
          if (!this.trabajadores.includes(element)) {
            this.trabajadores = [...this.trabajadores, element];
          }
        });
        this._unsubscribeIndividual.next('');
      });
  }

  getAllDepartamentosByEmpresa() {
    this.departamentosSE.getDepartamentosByEmpresa();
    this.departamentosSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        value.forEach((element) => {
          if (!this.departamentos.includes(element)) {
            this.departamentos = [...this.departamentos, element];
          }
        });
        this.departamentosSE.clearResult();
        this._unsubscribeIndividual.next('');
      });
  }
  filtro = 'nombre';
  asc=true;
  activarFiltro(parametro:string){
    if(parametro == this.filtro){
      this.asc = !this.asc;
    }else{
      this.asc = true;
      this.filtro = parametro;
    }
    this.ordenarEquipo(this.filtro,this.asc);
  }
  ordenarEquipo(parametro:string,asc:boolean){
    console.log(this.trabajadores[0][parametro]);
    this.trabajadores.sort((a, b) => {
      if (a[parametro] > b[parametro]) {
        return asc ? 1 : -1;
      }
      if (a[parametro] < b[parametro]) {
        return asc ? -1 : 1;
      }
      return 0;
    });
  }
}
