import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css']
})
export class GanttComponent implements OnInit {

  constructor() { }

  @Input() fases = [];
  @Input() hoy = true;
  @Input() height = '500px';  
  @Input() anchoDia = 20;
  
  today = new Date();
  
  ngOnChanges(changes) {
    console.log(changes);
    if(changes.fases != undefined || changes.anchoDia != undefined){
      this.prepararDatos();
      this.calcularPosiones();
      this.calcularMesesString();
      this.calcularHoy();
  }
}
  ngOnInit(): void {
    this.prepararDatos();
    this.calcularPosiones();
  }
  prepararDatos(){
    let fechaMin = new Date(this.fases[0].tareas[0].start);
    let fechaMax = new Date(this.fases[0].tareas[0].end);
    for(let fase of this.fases){
      let orden = 0;
      for(let tarea of fase.tareas){
        let t:any = tarea;
        t.order = orden;
        tarea= t;
        orden++;
        if(new Date(tarea.start) < fechaMin){
          fechaMin = new Date(tarea.start);
        }
        if(new Date(tarea.end) > fechaMax){
          fechaMax = new Date(tarea.end);
        }
      }
    }
    //restar dos dias a la fecha minima
    fechaMin.setDate(fechaMin.getDate() - 5);
    this.fechaInicio = fechaMin;
    
    this.fechaFin = fechaMax;
  }
  anchoFases = '0px';
  fechaInicio = new Date('2020-04-20');
  fechaFin = new Date('2020-04-20');
  taskHeight = 30;
  taskHeightComprimida = 10;
  margen = 7;
  tareasPosicion = [];

calcularPosiones() {
    this.tareasPosicion = [];
    let totComprimidas = 0;
    let totNoComprimidas = 0;
    for(let fase of this.fases) {
      let tareasComrpimidas = 0;
      for(let tarea of fase.tareas) {
        this.tareasPosicion.push(this.calcularPosicion(tarea,tareasComrpimidas));
        if(tarea.comprimida) {
          tareasComrpimidas++;
          totComprimidas++;
        }else{
          totNoComprimidas++;
        }
      }
    }

    
    this.altoTodasTareas = (totNoComprimidas)*(this.taskHeight + this.margen) + (totComprimidas)*(this.taskHeightComprimida + this.margen) + 30 + 50 * this.fases.length;
}

calcularHoy(){
  let inicio = this.today;
  let diferencia = inicio.getTime()-this.fechaInicio.getTime();
  diferencia = this.segundosToDias(diferencia);
  return (diferencia*this.anchoDia+100) + 'px';
}
mesesOrden = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
mesesString = [];

calcularMesesString() {
    let meses = [];
    let mes = this.fechaInicio.getMonth();
    let anio = this.fechaInicio.getFullYear();
    let fecha = new Date(anio, mes, this.fechaInicio.getDate());
    let margen = 0;
    while(fecha < this.fechaFin) {
      let dias = this.diasDuracionMes(fecha.getDate(),fecha.getMonth(), fecha.getFullYear());
      meses.push({left:margen*this.anchoDia,width:(dias*this.anchoDia)-1,nombre:this.mesesOrden[fecha.getMonth()]});
      margen+= dias;
      fecha.setDate(1);
      fecha.setMonth(fecha.getMonth() + 1);
    }

    
    let diferencia = fecha.getTime()-this.fechaInicio.getTime();
    diferencia = this.segundosToDias(diferencia);
    this.anchoFases = diferencia*this.anchoDia + 'px';
    this.mesesString = meses;
}

diasDuracionMes(dia,mes, anio) {
    let fecha = new Date(anio, mes, dia);
    let dias = 0;
    while(fecha.getMonth() == mes) {
      dias++;
      fecha.setDate(fecha.getDate() + 1);
    }
    return dias;
}
altoTodasTareas = 0;
  calcularPosicion(tarea, tareasComrpimidas) {
    let inicio = new Date(tarea.start);
    let fin = new Date(tarea.end);
    let duracion = fin.getTime() - inicio.getTime();
    //duracion a dias
    let dias = this.segundosToDias(duracion)+1;

    let diferencia = inicio.getTime()-this.fechaInicio.getTime();
    diferencia = this.segundosToDias(diferencia);
    let alto = this.taskHeight;
    if(tarea.comprimida) {
      alto = this.taskHeightComprimida;
    }
    let arriba = (tarea.order - tareasComrpimidas)*(this.taskHeight + this.margen) + (tareasComrpimidas)*(this.taskHeightComprimida + this.margen);
    return {
      left: diferencia*this.anchoDia + 'px',
      top: arriba + 'px',
      width: dias*this.anchoDia + 'px',
      height: alto + 'px'
    }    
  }
  comprimirFase(fase){
    this.fases[fase].comprimida = !this.fases[fase].comprimida;
    this.fases[fase].tareas.forEach(tarea => {
      tarea.comprimida = this.fases[fase].comprimida;
    });
    this.calcularPosiones();
  }
  calcularTamFase(fase){
    let tam = 0;
    this.fases[fase].tareas.forEach(tarea => {
      if(tarea.comprimida){
        tam += this.taskHeightComprimida + this.margen;
      }else{
        tam += this.taskHeight + this.margen;
      }
    });
    return tam + 'px';
  }

  segundosToDias(segundos) {
    return segundos / (1000 * 3600 * 24);
  }

  indiceFaseTarea(fase, tarea){
    let cont = 0;
    for(let i = 0; i < fase; i++) {
      cont += this.fases[i].tareas.length;
    }
    cont += tarea;
    return cont;
  }
}
