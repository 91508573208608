import {Component,Inject,OnInit, ViewChild} from '@angular/core';
import { LoginService } from 'src/app/Services/login.service';
import { DOCUMENT } from '@angular/common';
import { PopupService } from 'src/app/Services/popup.service';
import { PopupInformacionEmpleadoComponent } from 'src/app/Components/popup-informacion-empleado/popup-informacion-empleado.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Services/user.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/Interfaces/unsubscribesDestroy';
import { LoaderService } from 'src/app/Services/loader.service';
import { EmpresasService } from 'src/app/Services/empresas.service';
import { Empresa } from 'src/app/Interfaces/empresa';
import { FinanzasService } from 'src/app/Services/finanzas.service';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  filtro: string = '';
  porcentajeCortoPlazo: number = 0;
  porcentajeMedioPlazo: number = 0;
  porcentajeLargoPlazo: number = 0;
  hoy;


  @ViewChild('chartRentabilidad') chartRentabilidad!: ChartComponent;
  
  mesSeleccionado = 'Mayo';
cambioFecha(){
  if(this.mesSeleccionado == 'Abril' && this.anyoSeleccionado == '2023'){
    this.dailys = {
      correctos: 47,
      incorrectos: {
        subestimacion: 8,
        dependencia: 5,
        reasignacion: 6,
        enfermedad: 4,
      }
    }

    
    this.alertasRentabilidad = [
      {
        nombre: 'EcoWave',
        porcentaje: 50,
        dato: 0.5,
        color: '#F2C94C',
        abreviatura: 'EW',
        id:54,
      },
      {
        nombre: 'SmartFarm',
        porcentaje: 50,
        dato: 0.058,
        color: '#2D9CDB',
        abreviatura: 'SF',
        id:6,
      },
    ];
  
    this.alertasCostes = [
      {
        nombre: 'EcoWave',
        porcentaje: 50,
        dato: 0.01,
        color: '#F2C94C',
        abreviatura: 'EW',
        id:54,
      },
      {
        nombre: 'UrbanTrek',
        porcentaje: 75,
        dato: 0.02,
        color: '#BB6BD9',
        abreviatura: 'UT',
        id:5,
      },
      {
        nombre: 'SmartFarm',
        porcentaje: 50,
        dato: 0.08,
        color: '#2D9CDB',
        abreviatura: 'SF',
        id:6,
      },
    ];
  
    this.alertasProgreso = [ 
      {
        nombre: 'NeuroMind',
        porcentaje: 100,
        dato: 0.01,
        color: '#EB5757',
        abreviatura: 'NM',
        id:4,
      },
    ];

  }else{
  if(this.mesSeleccionado == 'Mayo' && this.anyoSeleccionado == '2023'){
    this.dailys = {
      correctos: 15,
      incorrectos: {
        subestimacion: 2,
        dependencia: 1,
        reasignacion: 4,
        enfermedad: 0,
      }
    };

    this.alertasRentabilidad = [
      {
        nombre: 'UrbanTrek',
        porcentaje: 75,
        dato: 0.13,
        color: '#BB6BD9',
        abreviatura: 'UT',
        id:5,
      },
    ];
  
    this.alertasCostes = [
      {
        nombre: 'SmartFarm',
        porcentaje: 50,
        dato: 0.02,
        color: '#2D9CDB',
        abreviatura: 'SF',
        id:6,
      },
    ];
  
    this.alertasProgreso = [ 
      {
        nombre: 'EcoWave',
        porcentaje: 50,
        dato: 0.153,
        color: '#F2C94C',
        abreviatura: 'EW',
        id:54,
      },  
      {
        nombre: 'NeuroMind',
        porcentaje: 100,
        dato: 0.05,
        color: '#EB5757',
        abreviatura: 'NM',
        id:4,
      },
      {
        nombre: 'UrbanTrek',
        porcentaje: 75,
        dato: 0.13,
        color: '#BB6BD9',
        abreviatura: 'UT',
        id:5,
      },
      {
        nombre: 'SmartFarm',
        porcentaje: 50,
        dato: 0.035,
        color: '#2D9CDB',
        abreviatura: 'SF',
        id:6,
      },
    ];

  }else{
    this.dailys = {
    correctos: 0,
    incorrectos: {
      subestimacion: 0,
      dependencia: 0,
      reasignacion: 0,
      enfermedad: 0,
    }
  }
  
  this.alertasRentabilidad = [];

  this.alertasCostes = [];

  this.alertasProgreso = [];
  }
}


this.cambioTipo('Total');

}
anyoSeleccionado = '2023';

  meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Setiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];

  anyos = [
    '2021',
    '2022',
    '2023'
  ]

  graficaRentabilidad: any = {
    annotations: {
    },
    colors: ["#71F0CA"],
    series: [
      {
        name: "Rentabilidad",
        data: []
      }
    ],
    chart: {
      animations: {
        enabled: false
      },
      height: 200,
      toolbar:{
        show:false
      },
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    grid: {
      show: false,
        padding: {
          top: 0,
          bottom: 0,
          left: 0
        }
      
    },
    xaxis: {
      type: "datetime",
      labels: {
        style:{
          colors:'#192b45',
          fontFamily: 'Satoshi',          
        },
      },
      axisTicks: {
        show: false,
      }
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return  Math.round(value) + '%';
        },
        style:{
          colors:'#1f586b',
          fontFamily: 'Satoshi',          
        },
        offsetX: -20,
      },
      forceNiceScale: true,
    }
  }
  
  eventos = [
    {
      tipo: 'Entrega',
      texto: 'Entrega: Fase (Producción)',
      fecha: new Date('2023-07-7'),
      proyecto: 6
    },
    {
      tipo: 'Hito de pago',
      texto: 'Hito de cobro 1 (1/3) · 10.000 €',
      fecha: new Date('2023-07-17'),
      proyecto: 4
    },
    {
      tipo: 'Entrega',
      texto: 'Entrega: Fase (Preproducción)',
      fecha: new Date('2023-07-28'),
      proyecto: 3
    },
    {
      tipo: 'Hito de pago',
      texto: 'Hito de cobro 1 (1/3) · 15.000 €',
      fecha: new Date('2023-07-31'),
      proyecto: 2
    },
    {
      tipo: 'Entrega',
      texto: 'Entrega: Fase (Producción)',
      fecha: new Date('2023-08-07'),
      proyecto: 5
    },
  ]

  fechaInicioCalendario = new Date();
  fechaFinCalendario = new Date();

  dailys = {
    correctos: 15,
    incorrectos: {
      subestimacion: 2,
      dependencia: 1,
      reasignacion: 4,
      enfermedad: 0,
    }
  }
  colores = ['#1de572', '#fd454d'];
  nombresDaily = ['Validados', 'No validados'];
  dailyVal = [];

  cambioTipo(val){
    if(val == 'Total'){
      this.dailyVal = [];
      this.dailyVal.push(this.dailys.correctos);
      this.dailyVal.push(this.dailys.incorrectos.subestimacion + this.dailys.incorrectos.dependencia + this.dailys.incorrectos.reasignacion + this.dailys.incorrectos.enfermedad);
      
      this.colores = ['#1de572', '#fd454d'];
      this.nombresDaily = ['Validados', 'No validados'];
    }else{
      this.dailyVal = [];
      this.colores = ['#2d9cdb', '#bb6bd9', '#f2994a', '#d3e1fc'];
      this.dailyVal.push(this.dailys.incorrectos.subestimacion);
      this.dailyVal.push(this.dailys.incorrectos.dependencia);
      this.dailyVal.push(this.dailys.incorrectos.reasignacion);
      this.dailyVal.push(this.dailys.incorrectos.enfermedad);
      this.nombresDaily = ['Subestimación', 'Dependencia', 'Reasignación', 'Enfermedad'];
    }
  }
  proyectosActivos =[
    {
      nombre: 'EcoWave',
      porcentaje: 50,
      color: '#F2C94C',
      abreviatura: 'EW',
      id:54,
    },
    {
      nombre: 'AI4Education',
      porcentaje: 100,
      color: '#d3e1fc',
      abreviatura: 'AI4',
      id:2,
    },
    {
      nombre: 'GreenHive',
      porcentaje: 25,
      color: '#27AE60',
      abreviatura: 'GH',
      id:3,
    },
    {
      nombre: 'NeuroMind',
      porcentaje: 100,
      color: '#EB5757',
      abreviatura: 'NM',
      id:4,
    },
    {
      nombre: 'UrbanTrek',
      porcentaje: 75,
      color: '#BB6BD9',
      abreviatura: 'UT',
      id:5,
    },
    {
      nombre: 'SmartFarm',
      porcentaje: 50,
      color: '#2D9CDB',
      abreviatura: 'SF',
      id:6,
    },
    {
      nombre: 'FoodHero',
      porcentaje: 75,
      color: '#F2994A',
      abreviatura: 'FH',
      id:7,
    },
    
  ]

  alertasRentabilidad = [
    {
      nombre: 'UrbanTrek',
      porcentaje: 75,
      dato: 0.13,
      color: '#BB6BD9',
      abreviatura: 'UT',
      id:5,
    },
  ]

  alertasCostes = [
    {
      nombre: 'SmartFarm',
      porcentaje: 50,
      dato: 0.02,
      color: '#2D9CDB',
      abreviatura: 'SF',
      id:6,
    },
  ]

  alertasProgreso = [
    {
      nombre: 'EcoWave',
      porcentaje: 50,
      dato: 0.153,
      color: '#F2C94C',
      abreviatura: 'EW',
      id:54,
    },  
    {
      nombre: 'NeuroMind',
      porcentaje: 100,
      dato: 0.05,
      color: '#EB5757',
      abreviatura: 'NM',
      id:4,
    },
    {
      nombre: 'UrbanTrek',
      porcentaje: 75,
      dato: 0.13,
      color: '#BB6BD9',
      abreviatura: 'UT',
      id:5,
    },
    {
      nombre: 'SmartFarm',
      porcentaje: 50,
      dato: 0.035,
      color: '#2D9CDB',
      abreviatura: 'SF',
      id:6,
    },
  ]



  mostrarPotencial = false;
  @ViewChild('chart') chart!: ChartComponent;
  
  grafica: any = {
    legend:{ 
      show: true, 
      fontFamily: 'Satoshi',
      itemMargin: {
        horizontal: 8,
        vertical: 4
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50px",
        borderRadius: 7,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: 'last'

      }
    },
    annotations: {
      yaxis: [
        {
          y: 100,
          borderColor: '#CB8A8A',
          strokeDashArray: 0          
        }
      ]
    },
    series: [
    ],
    chart: {
      animations: {
        enabled: false
      },
      height: 480,
      toolbar:{
        show:false
      },
      type: "line",
      stacked: true,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 0,
    },
    fill: {
      opacity: 0.8,  
    },
    colors:[],
    grid: {
      show: true,
        padding: {
          top: 0,
          bottom: 0,
          left: 15
        }
      
    },
    xaxis: {
      show:true,
      labels: {
        show: true,
        style: {
          cssClass: 'labelSimulador',
          colors:'#192b45',
          fontFamily: 'Satoshi'
        }
      },
      crosshairs:{
        show:false,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder:{
        show:false,
      },
      axisTicks: {
        show: false,
      }
    },
    yaxis: {
      labels: {
        style:{
          cssClass: 'yaxisSimulador',
          colors:'#1f586b',
          fontFamily: 'Satoshi'
        },
        formatter: function(value) {
          value = Math.round(value);
          return value + "%"
        }
      },
      forceNiceScale: false,
      min: 0,
      max: () => this.maxvalY,
      show:true,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Math.round(val) + '%';
        },
      }
    }
  }
  maxvalY = 110;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public popup: PopupService,
    public router: Router,
    private loginSE: LoginService,
    public userSE: UserService,
    protected finanzasSE: FinanzasService,
    public loaderSE: LoaderService,
    public empresaSE: EmpresasService
  ) {
    super();
  }

  ngOnDestroy() {
    this._unsubscribeIndividual.next('');
  }

  //funcion que interpola entre 0 y 100 a -43 y 223

  conversion(x: number) {
    if(x > 100){
      return 223;
    }
    return (x * 266) / 100 - 43;
  }
  
  lunes;
  domingo;
  incidenciasActual = 0;
  diferencia = 0;
  incidenciaSigno = '+';
  rentabilidad = 0;
  rentabilidadAnterior = 0;
  coste = 0;
  costeAnterior = 0;

  endWeek(day) {
    var date = new Date(day.valueOf());
    date.setDate(date.getDate() + 6);
    return date;
  }
  admin = false;
  avanzarUnMes(){
    this.fechaInicioCalendario = new Date(this.fechaInicioCalendario);
    this.fechaInicioCalendario.setMonth(this.fechaInicioCalendario.getMonth() + 1);
    this.actualizarFechaFinCalendario();
  }
  retrocederUnMes(){
    this.fechaInicioCalendario = new Date(this.fechaInicioCalendario);
    this.fechaInicioCalendario.setMonth(this.fechaInicioCalendario.getMonth() - 1);
    this.actualizarFechaFinCalendario();
  }

  private actualizarFechaFinCalendario(){
    let fechaInicioCopia = new Date(this.fechaInicioCalendario);
    this.fechaFinCalendario=fechaInicioCopia;
    this.fechaFinCalendario.setDate(1);
    this.fechaFinCalendario.setMonth(this.fechaFinCalendario.getMonth() + 1);
    this.fechaFinCalendario.setDate(this.fechaFinCalendario.getDate() - 1);
    this.fechaFinCalendario.setHours(23, 59, 59, 999);
  }
  
  valoresRentabilidad = [ 
    92, 85, 89, 91, 88, 95, 100, 96, 99, 105, 
    103, 100, 96, 98, 94, 102, 105, 99, 97, 105,
    102, 103, 101, 103, 105, 102, 99, 105, 101, 103,
    100
  ];
  /*[ 
    85, 87, 91, 93, 96, 102, 106, 110, 115, 121, 
    119, 123, 119, 124, 128, 132, 137, 133, 131, 137,
    141, 144, 149, 147, 152, 154, 148, 152, 157, 160,
    162
  ];*/
  rellenarRentabilidad(){
    this.graficaRentabilidad.series[0].data = [];
    
    let hoy = new Date();
    let anterior = new Date();
    anterior.setMonth(anterior.getMonth() - 1);
    let cont = 0;
    while(anterior.getTime() <= hoy.getTime()){
      this.graficaRentabilidad.series[0].data.push([anterior.getTime(), this.valoresRentabilidad[cont]]);
      anterior.setDate(anterior.getDate() + 1);
      cont++;
    }
    console.log(this.graficaRentabilidad.series[0].data);
    
    this.chartRentabilidad.updateSeries(this.graficaRentabilidad.series);

  }
  ngOnInit(): void {
    //poner el dia 1 del mes en la fecha de inicio del calendario
    this.fechaInicioCalendario = new Date();
    this.fechaInicioCalendario.setDate(1);
    this.fechaInicioCalendario.setHours(0, 0, 0, 0);
    //poner el ultimo dia del mes en la fecha de fin del calendario
    this.fechaFinCalendario = new Date();
    this.fechaFinCalendario.setDate(1);
    this.fechaFinCalendario.setMonth(this.fechaFinCalendario.getMonth() + 1);
    this.fechaFinCalendario.setDate(this.fechaFinCalendario.getDate() - 1);
    this.fechaFinCalendario.setHours(23, 59, 59, 999);

    
    





    this.admin = this.loginSE.isIntermedio();
    this.hoy = new Date();
    this.lunes = this.getMonday(this.hoy);
    this.domingo = this.endWeek(this.lunes);
    if (
      localStorage.getItem('token') === null &&
      localStorage.getItem('login') != 'login'
    ) {
      this.loginSE.desconectar();
    } else {
      if (localStorage.getItem('numEmpresa') === null) {
        this.saveEmpresaId();
        setTimeout(() => {
          this.getEmpresa();
        }, 1000);
      } else {
        this.getEmpresa();
      }
    }
  }
  abrirInformacionEmpleado(trabajador: any) {
    this.popup.openPopup(PopupInformacionEmpleadoComponent, trabajador);
  }
  borrarTexto() {
    this.filtro = '';
  }

  setPorcentaje(cant: number, elemento: string) {
    var pixel = 472 - (472 / 100) * cant;
    this._document
      .querySelector(elemento)
      ?.setAttribute('stroke-dashoffset', pixel + 'px');
  }

  private saveEmpresaId() {
    this.userSE.getMyUser();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        
        localStorage.setItem('numEmpresa', value.empresas[0].id);
        localStorage.setItem('idUser',value.id);
        localStorage.setItem('nombre',value.nombre);
        this._unsubscribeIndividual.next('');
        this.userSE.clearResult();
      });
  }
proyectosGrafica = [];
potenciales = [];
reales = [];
  private getEmpresa() {
    this.empresaSE.getMyEmpresa();
    this.loaderSE.open();
    this.empresaSE
      .getResult()
      .pipe(takeUntil(this._unsubscribeIndividual))
      .subscribe((value: Empresa) => {
        if (value == null) {
          return;
        }
        this._unsubscribeIndividual.next(null);
        this.loaderSE.closeContador();
      });
      this.loaderSE.open();
    this.empresaSE.getStatusEmpresa();
    this.empresaSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubscribeIndividual2))
      .subscribe((value: any) => {
        if (value == null) {
          return;
        }

        this.porcentajeCortoPlazo = value.OcupacionCorto * 100;
        this.porcentajeMedioPlazo = value.OcupacionMedio * 100;
        this.porcentajeLargoPlazo = value.OcupacionLargo * 100;
        this.proyectos = value.Proyectos;
        this.incidenciasActual = value.IncidenciasActual;
        this.diferencia = Math.abs(value.IncidenciasActual - value.IncidenciasAnterior);
        if(value.IncidenciasActual - value.IncidenciasAnterior < 0){
          this.incidenciaSigno = '-';
        }
        if(value.IncidenciasActual - value.IncidenciasAnterior == 0){
          this.incidenciaSigno = '';
        }

        this.rentabilidad = value.RentabilidadMedia;
        this.rentabilidadAnterior = value.RentabilidadMediaAnterior;
        this.coste = value.CosteEmpresa;
        this.costeAnterior = value.CosteEmpresaAnterior;

      let semana = 0;
      console.log(value.Simulacion);
      for(let i = 0; i < value.Simulacion.length; i++){
        this.reales[i] = 0;
        let semanaProyectos  = value.Simulacion[i];
          if(semanaProyectos != undefined){
            semanaProyectos.forEach(element => {
              if(this.proyectosGrafica[element.proyecto.id] == undefined){
                this.proyectosGrafica[element.proyecto.id] = {'id':element.proyecto.id, 'abreviatura':element.proyecto.abreviatura,'nombre':element.proyecto.nombre,'potencial':element.proyecto.potencial,'carga':[],'activo':null};
                if(element.proyecto.potencial){
                  this.proyectosGrafica[element.proyecto.id].activo = false;
                }else{
                  this.proyectosGrafica[element.proyecto.id].activo = true;
                }
                for(let i = 0; i < value.Simulacion.length; i++){
                  this.proyectosGrafica[element.proyecto.id].carga[i] = 0;
                }
              }
              this.proyectosGrafica[element.proyecto.id].carga[semana] = element.carga*100;
              if(!this.proyectosGrafica[element.proyecto.id].potencial){
                this.reales[semana] = (this.reales[semana]*100 + element.carga*10000)/100;
              }
            });
          }
          semana++;
        }
        let arrayAuxiliar = [];
        this.proyectosGrafica.forEach(element => {
          if(element.potencial){
          console.log(element);
          arrayAuxiliar.push(element);
          }else{
            if(this.proyectosReales.length == 0){
              this.proyectosReales = element.carga;
            }else{
              for(let i = 0; i < element.carga.length; i++){
                this.proyectosReales[i] += element.carga[i];
              }
            }
          }
        });          
        this.proyectosGrafica = arrayAuxiliar;
        this.pintarGrafica();
        this._unsubscribeIndividual2.next(null);
        this.loaderSE.closeContador();
      });
  }
proyectosReales = [];
  pintarGrafica() {

    this.potenciales = [];
    for(let semana = 0; semana < this.reales.length; semana++){
      this.potenciales[semana] = 0;
    }
    //revisar los proyectos potenciales activados
    this.proyectosGrafica.forEach(element => {
      if(element.potencial && element.activo){
        for(let semana = 0; semana < element.carga.length; semana++){
          this.potenciales[semana] += element.carga[semana];
        }
      }
    });

    //comprobar la y maxima
    this.maxvalY = 110;
    for(let semana = 0; semana < this.reales.length; semana++){
      console.log(this.potenciales[semana] + ' ' + this.reales[semana] );
      if(this.potenciales[semana] + this.reales[semana] > this.maxvalY){
        this.maxvalY = this.potenciales[semana] + this.reales[semana];
      }
    }
    console.log(this.maxvalY);
    //redondear maxvalY a 10
    this.maxvalY = Math.ceil(this.maxvalY/10)*10;
    //sacar cuantas divisiones entre 10 hay que hacer
    let divisiones = this.maxvalY/10;
    if(divisiones > 13){
      divisiones = 13;
    }
    this.grafica.yaxis.tickAmount = divisiones;
    this.grafica.yaxis.max = this.maxvalY;
    console.log(this.grafica.yaxis.max);

    this.grafica.series = [];
    this.grafica.colors = [];
    this.grafica.series.push({
      name: "Carga Real",
      type: "area",
      data: this.reales
    });
    this.grafica.colors.push('#EBFAFF');
    
    this.grafica.series.push({
      name: "Carga Potencial",
      type: "area",
      data: this.potenciales
    });
    this.grafica.colors.push('#FFF5E8');
    
    this.grafica.series.push({
      name: "Proyectos Reales",
      type: "column",
      data: this.proyectosReales
    });

    
    this.grafica.colors.push(function({ value, seriesIndex, w }) {
      if(value > 100){
        return '#D97161';
      }else{
        return '#B1C4D0';
      }
    });


    this.proyectosGrafica.forEach(element => {
      if(element.activo){
        this.grafica.series.push({
          name: element.abreviatura,
          type: "column",
          data: element.carga
        });
        if(element.potencial){
          this.grafica.colors.push('#E2D3C3');
        }else{
          this.grafica.colors.push(function({ value, seriesIndex, w }) {
            let semanas = [];
            let cont = 0;
            w.config.series[0].data.forEach(semana => {
              if(semana > 100){
                semanas.push(cont);
              }
              cont++;
            });
            let valoresSemanas = [];
            semanas.forEach(semana => {
              valoresSemanas.push(w.config.series[seriesIndex].data[semana]);
            });
            console.log(valoresSemanas);

            //si value está en valoresSemanas, se pinta de rojo
            if(valoresSemanas.includes(value)){
              return '#D97161';
            }else{
              return '#B1C4D0';
            }
          });
        }
      }
    });
    
    this.chart.updateSeries(this.grafica.series);
    this.rellenarRentabilidad();
   // this.chart.updateOptions(this.grafica);
  }
  semana = 0;
  serieAnterior = 0;

  valorAbsoluto(valor: number) {
    //si NaN devuelve 0
    if (isNaN(valor)) {
      return 0;
    }
    return Math.abs(valor);
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  proyectos = [];
}
