<div class="dashboardGeneralContainer">
  <div class="sideBarGeneralContainer">
    <sidebar [elementoActivo]="'dashboard'"></sidebar>
  </div>
  <ng-container>
    <div class="dataGeneralContainer">
      <div class="dataHeaderContainer">
        <app-header-bar></app-header-bar>
      </div>
      <div class="dataBodyContainer">
        <div class="semanaContainer">
          <span>{{ hoy | date: "EEEE" |  titlecase }}, {{ hoy | date: "d \'d\'e MMMM \'d\'e y" }}</span>
        </div>
        <div class="tarjetasContainer">
          <div class="tarjetaDashboard">
          <div class="etiquetaContainer">Corto Plazo</div>
          <grafico-velocimetro [porcentaje]="porcentajeCortoPlazo"></grafico-velocimetro>
            <div class="ocupacion" [class.alta]="porcentajeCortoPlazo >= 66" [class.baja]="porcentajeCortoPlazo < 33" [class.media]="porcentajeCortoPlazo < 66 && porcentajeCortoPlazo >= 33">
              <ng-container *ngIf="porcentajeCortoPlazo < 33">Ocupación baja</ng-container>
              <ng-container *ngIf="porcentajeCortoPlazo < 66 && porcentajeCortoPlazo >= 33">Ocupación media</ng-container>
              <ng-container *ngIf="porcentajeCortoPlazo >= 66">Ocupación alta</ng-container>
            </div>
          </div>
          <div class="tarjetaDashboard">
          <div class="etiquetaContainer">Medio Plazo</div>
          <grafico-velocimetro [porcentaje]="porcentajeMedioPlazo"></grafico-velocimetro>
            <div class="ocupacion" [class.alta]="porcentajeMedioPlazo >= 66" [class.baja]="porcentajeMedioPlazo < 33" [class.media]="porcentajeMedioPlazo < 66 && porcentajeMedioPlazo >= 33">
              <ng-container *ngIf="porcentajeMedioPlazo < 33">Ocupación baja</ng-container>
              <ng-container *ngIf="porcentajeMedioPlazo < 66 && porcentajeMedioPlazo >= 33">Ocupación media</ng-container>
              <ng-container *ngIf="porcentajeMedioPlazo >= 66">Ocupación alta</ng-container>
            </div>
          </div>
          <div class="tarjetaDashboard">
          <div class="etiquetaContainer">Largo Plazo</div>
          <grafico-velocimetro [porcentaje]="porcentajeLargoPlazo"></grafico-velocimetro>
            <div class="ocupacion" [class.alta]="porcentajeLargoPlazo >= 66" [class.baja]="porcentajeLargoPlazo < 33" [class.media]="porcentajeLargoPlazo < 66 && porcentajeLargoPlazo >= 33">
              <ng-container *ngIf="porcentajeLargoPlazo < 33">Ocupación baja</ng-container>
              <ng-container *ngIf="porcentajeLargoPlazo < 66 && porcentajeLargoPlazo >= 33">Ocupación media</ng-container>
              <ng-container *ngIf="porcentajeLargoPlazo >= 66">Ocupación alta</ng-container>
            </div>
          </div>
          
          <div *ngIf="false" class="tarjetaDashboard informacion">
            <div class="etiquetaContainer">Margen medio proyectos último mes</div>
            <div class="datos"><div class="valor">{{rentabilidad|number:'0.0-2'}} %</div>
            <div class="diferencial" [class.bien]="rentabilidad>rentabilidadAnterior" [class.mal]="rentabilidad<rentabilidadAnterior"><span class="flecha"></span>{{ valorAbsoluto(1 - (rentabilidad/rentabilidadAnterior)) | percent:'0.0-2'}}</div></div>
          </div>
          <div *ngIf="false" class="tarjetaDashboard informacion">
            <div class="etiquetaContainer">Coste de este mes</div>
            <div class="datos"><div class="valor">{{coste | currency:'EUR'}}</div>
            <div class="diferencial" [class.bien]="coste>costeAnterior" [class.mal]="coste<costeAnterior"><span class="flecha"></span>{{ valorAbsoluto(1 - (coste / costeAnterior)) | percent:'0.0-2'}}</div></div>
          </div>
          <div *ngIf="false" class="tarjetaDashboard informacion">
            <div class="etiquetaContainer">Incidencias este mes</div>
            <div class="datos"><div class="valor">{{incidenciasActual}} incidencias</div>
            <div class="diferencial" [class.bien]="incidenciaSigno == '-'" [class.mal]="incidenciaSigno == '+'">{{incidenciaSigno}} {{diferencia}}</div></div>
          </div>
        </div>

        <div class="tarjetasContainer">
          
          <div class="tarjetaDashboard">
            <div class="etiquetaContainer">Proyectos activos</div>
            <div class="columnas" *ngIf="proyectosActivos.length > 0">
              <div class="numeroGrande">
                {{proyectosActivos.length}}
              </div>
              <div class="contenedorPoryectos">
                <div [routerLink]="'/proyectos/'+proyecto.id" class="proyecto" *ngFor="let proyecto of proyectosActivos">
                  <div class="color" [style.background]="proyecto.color"></div>
                  <div class="abreviatura">{{proyecto.abreviatura}}</div>
                  <div class="nombre">{{proyecto.nombre}}</div>
                </div>
              </div>
            </div>
            <div class="elementoVacio" *ngIf="proyectosActivos.length == 0">
              Ningún proyecto activo
            </div>
          </div>
            
          <div class="tarjetaDashboard doble">
            <div class="etiquetaContainer">Rentabilidad</div>
            <div class="elementoVacio" *ngIf="graficaRentabilidad.series[0].data.length == 0">
              Sin datos
            </div>
            
            <div class="columnas">
                
              <apx-chart
              #chartRentabilidad
              [series]="graficaRentabilidad.series"
              [chart]="graficaRentabilidad.chart"
              [xaxis]="graficaRentabilidad.xaxis"
              [yaxis]="graficaRentabilidad.yaxis"
              [dataLabels]="graficaRentabilidad.dataLabels"
              [grid]="graficaRentabilidad.grid"
              [stroke]="graficaRentabilidad.stroke"
              [legend]="{ show: false}"
              [colors]="graficaRentabilidad.colors"
              ></apx-chart>

              <div class="numeroGrande">
                100%
              </div>
            </div>
          </div>
          


        </div>
        <div class="tarjeta graficaSimulador">     
          <div class="etiquetaContainer">Ocupación de equipo por proyectos
            <div *ngIf="proyectosGrafica.length > 0" >Activar proyectos potenciales <input type="checkbox" [(ngModel)]="mostrarPotencial"></div>
          </div>
          <div class="contenedorGrafico" [class.mostrarProyectos]="mostrarPotencial">
          <apx-chart
          #chart
          [series]="grafica.series"
          [chart]="grafica.chart"
          [xaxis]="grafica.xaxis"
          [yaxis]="grafica.yaxis"
          [dataLabels]="grafica.dataLabels"
          [grid]="grafica.grid"
          [stroke]="grafica.stroke"
          [tooltip]="grafica.tooltip"
          [legend]="grafica.legend"
          [plotOptions]="grafica.plotOptions"
          [fill]="grafica.fill"
          [annotations]="grafica.annotations"
          [colors]="grafica.colors"
          ></apx-chart>
          <div class="proyectos" *ngIf="mostrarPotencial">
            <ng-container *ngFor="let proyecto of proyectosGrafica">
            <div class="lineaProyecto" *ngIf="proyecto.potencial">
              <input (change)="pintarGrafica()" type="checkbox" [(ngModel)]="proyecto.activo"> {{proyecto.nombre}}
            </div>
          </ng-container>
          </div>
        </div>
        </div>

        <div class="tarjetasInferiores">
          <div class="izquierda">
            <div class="selectorFecha">
              <ng-select [items]="meses" [(ngModel)]="mesSeleccionado" (change)="cambioFecha()"></ng-select>
              <ng-select [items]="anyos" [(ngModel)]="anyoSeleccionado" (change)="cambioFecha()"></ng-select>
            </div>
            <div class="agrupacion">
              <div class="tarjetaDashboard">
                <div class="etiquetaContainer">Dailys</div>
                <div class="contenedorDaily">
                  <div class="izq">
                    <grafico-pie [fontSize]="'37pt'" [valores]="dailyVal" [colores]="colores" [width]="'150px'"></grafico-pie>
                  </div>
                  <div class="der">
                    <menu-navegacion [opcionPorDefecto]="'Total'" [redondo]="true" [condensed]="true" [opciones]="['Total','Fallidos']" (emitOpcionSeleccionada)="cambioTipo($event)"></menu-navegacion>
                    <div class="contenedorLeyenda">
                      <div class="leyenda" *ngFor="let leyenda of nombresDaily; let i = index">
                        <span><strong>{{leyenda}}</strong> ({{dailyVal[i]}})</span> <span class="color" [style.background]="colores[i]"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tarjetaDashboard">
                <div class="etiquetaContainer">Alertas rentabilidad</div>
                <div *ngIf="alertasRentabilidad.length > 0" class="columnas">
                  <div class="numeroGrande">
                    {{alertasRentabilidad.length}}
                  </div>
                  <div class="contenedorPoryectos">
                  <div [routerLink]="'/proyectos/'+proyecto.id" class="proyecto" *ngFor="let proyecto of alertasRentabilidad">
                    <div class="estado" ><img class="flechaRoja" src="/assets/icons/FlechaRoja.png">{{proyecto.dato| percent: "1.0-1"}}</div>
                    <div class="abreviatura">{{proyecto.abreviatura}}</div>
                    <div class="nombre">{{proyecto.nombre}}</div>
                  </div>
                  </div>
                </div>              
                <div *ngIf="alertasRentabilidad.length == 0" class="elementoVacio">
                  Todo en orden
                </div>
              </div>
            </div>
            <div class="agrupacion">
              <div class="tarjetaDashboard">
                <div class="etiquetaContainer">Alertas costes</div>
                <div *ngIf="alertasCostes.length > 0" class="columnas">
                  <div class="numeroGrande">
                    {{alertasCostes.length}}
                  </div>
                  <div class="contenedorPoryectos">
                  <div [routerLink]="'/proyectos/'+proyecto.id" class="proyecto" *ngFor="let proyecto of alertasCostes">
                    <div class="estado" ><img class="flechaRoja" src="/assets/icons/FlechaRoja.png">{{proyecto.dato| percent: "1.0-1"}}</div>
                    <div class="abreviatura">{{proyecto.abreviatura}}</div>
                    <div class="nombre">{{proyecto.nombre}}</div>
                    </div>
                  </div>
                </div>              
                <div *ngIf="alertasCostes.length == 0" class="elementoVacio">
                  Todo en orden
                </div>
              </div>
              <div class="tarjetaDashboard">
                <div class="etiquetaContainer">Alertas progreso</div>
                <div *ngIf="alertasProgreso.length > 0"  class="columnas">
                  <div class="numeroGrande">
                    {{alertasProgreso.length}}
                  </div>
                  <div class="contenedorPoryectos">
                  <div [routerLink]="'/proyectos/'+proyecto.id" class="proyecto" *ngFor="let proyecto of alertasProgreso">
                    <div class="estado" ><img class="flechaRoja" src="/assets/icons/FlechaRoja.png">{{proyecto.dato| percent: "1.0-1"}}</div>
                    <div class="abreviatura">{{proyecto.abreviatura}}</div>
                    <div class="nombre">{{proyecto.nombre}}</div>
                    </div>
                  </div>
                </div>              
                <div *ngIf="alertasProgreso.length == 0" class="elementoVacio">
                  Todo en orden
                </div>
              </div>
            </div>
          </div>
          <div class="derecha">
            <div class="tarjetaDashboard">
              <div class="etiquetaContainer">Calendario</div>
              <div class="calendario">
                <button (click)="retrocederUnMes()"></button><span>{{fechaInicioCalendario | date:'MMM yyyy' | titlecase}}</span><button (click)="avanzarUnMes()"></button>
              </div>
              <div class="eventos">
                <ng-container *ngFor="let evento of eventos">
                  <div class="evento" *ngIf="evento.fecha >= fechaInicioCalendario && evento.fecha <= fechaFinCalendario ">
                  <div class="dia"><span class="sem">{{evento.fecha|date:"EEEE"}}</span><span class="fecha">{{evento.fecha|date:"d"}}</span></div>
                  <div class="infoEvento">
                    <div class="descripcion">{{evento.texto}}</div>
                    <div class="proyecto"><span class="color" [style.background]="proyectosActivos[evento.proyecto-1].color"></span>{{proyectosActivos[evento.proyecto-1].nombre}}</div>
                  </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        
        <ng-container *ngIf="false">
        <div class="tarjeta" *ngIf="proyectos.length > 0">
          <p>Estado proyectos</p>
          <div class="infoPoryectos">
            <estado-proyecto *ngFor="let proyecto of proyectos" [proyecto]="proyecto"></estado-proyecto>
          </div>
        </div>
      </ng-container>
      </div>
    </div>
  </ng-container>
</div>
